// Import CoreUI styles
@import "~@coreui/coreui/scss/coreui.scss";

// @import " https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.1/css/all.min.css";
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Russo+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,600;0,700;0,800;0,900;1,200&display=swap');
// custom styles
.p-datepicker {
  z-index: 10000 !important;
}

body {
  background-color: #f5f5f5;
  // font-family: "Rubik", sans-serif;
  font-family: 'Montserrat', sans-serif;
}
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6
{
  color: #777;
}
th
{
  font-weight: 600 !important;
}
a:hover, .text-dec-none, .btn-link:hover
{
  text-decoration: none;
}

.p-toast {
  z-index: 100001 !important;
}

.p-datatable-wrapper {
  font-size: 14px !important;
}

.p-datatable .p-datatable-tbody  tr  td {
  padding: 6px !important;
}
.p-4-extra-spacing
{
  padding: 2rem;
  padding-top: 1.1rem;
}
.p-datatable .p-datatable-thead > tr > th {
  padding: 6px !important;
  color: #666;
}
.p-datatable td button.p-button
{
  width: inherit !important;
  padding: 5px !important;
  font-size: 0px;
}
.p-datatable th
{
  font-size: 10px !important;
  text-transform: uppercase;
}
.p-datatable tbody
{
  font-size: 12px !important;
}
.p-datatable-thead .p-sortable-column-icon
{
  font-size: 12px !important;
}
.p-datatable .p-datatable-tbody > tr > td {
  padding: 0.2rem 0.4rem;
}

// .p-component {
//   // font-size: 0.7em;
//   // font-weight: bold;
// }

.dt-virtualScroll .loading-text {
  display: block;
  background-color: #f1f1f1;
  min-height: 19px;
  animation: pulse 1s infinite ease-in-out;
  text-indent: -99999px;
  overflow: hidden;
}
.ledger-details-view
{
  font-size: 14px;
}
.max-font-size
{
  font-size: 14px !important;
}
.group-title-text
{
  font-size: 12px;
  font-weight: 600;
} 
.status-font
{
  font-weight: 500;
}  
.group-card-shadow
{
      box-shadow: 0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05);
      transition: .3s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12);
}
// .card-zoom-hover:hover
// {
//   transform: scale(1.01);
//   box-shadow: 0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05);
// }
.link-anchor, .min-font-size
{
  font-size: 12px;
  cursor: pointer;
}
.review-min-font-size
{
  font-size: 12px;
}
.c-sidebar-nav-link, .c-sidebar-nav-dropdown-toggle
{
  padding: 0.65rem 1rem;
}
.overflow-elipsis
{
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis; 
  
}
.data-text
{
  font-size: 13px;
}

::-webkit-scrollbar {
  -webkit-overflow-scrolling: touch;
  height: 10px;
  width: 10px
  
}

::-webkit-scrollbar:hover {
  background-color: #e9e9e9;
  border: 1px solid #dbdbdb;
  border-radius: 5px;
}

::-webkit-scrollbar-button:end:increment,
::-webkit-scrollbar-button:start:decrement {
  background: 0;
  display: block;
  height: 0
}

::-webkit-scrollbar-track {
  background-clip: padding-box;
  border: solid transparent
}

::-webkit-scrollbar-track-piece {
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  -webkit-border-radius: 0;
  background-color: #e9e9e9;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  -moz-box-shadow: inset 1px 1px 0 rgba(0, 0, 0, .1), inset 0 -1px 0 rgba(0, 0, 0, .07);
  -webkit-box-shadow: inset 1px 1px 0 rgba(0, 0, 0, .1), inset 0 -1px 0 rgba(0, 0, 0, .07);
  background-clip: padding-box;
  background-color: rgba(0, 0, 0, .2);
  border: 0;
  box-shadow: inset 1px 1px 0 rgba(0, 0, 0, .1), inset 0 -1px 0 rgba(0, 0, 0, .07)
}

::-webkit-scrollbar-thumb:horizontal,
::-webkit-scrollbar-thumb:vertical {
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  -webkit-border-radius: 0;
  background-color: #ccc;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:active {
  -moz-box-shadow: inset 1px 1px 3px rgba(0, 0, 0, .33);
  -webkit-box-shadow: inset 1px 1px 3px rgba(0, 0, 0, .33);
  background-color: rgba(0, 0, 0, .44);
  box-shadow: inset 1px 1px 3px rgba(0, 0, 0, .33)
}

::-webkit-scrollbar-thumb:hover {
  background-color: #959595
}

::-webkit-scrollbar {
  width: 6px;
  border-radius: 5px;
  cursor: pointer;
 
}
/*-- chatbox scrollbar --*/
.chat-box::-webkit-scrollbar, .side-tasks-height::-webkit-scrollbar {
  width: 2px;
}

/* Track */
.chat-box::-webkit-scrollbar-track , .side-tasks-height::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
.chat-box::-webkit-scrollbar-thumb, .side-tasks-height::-webkit-scrollbar-thumb  {
  background: #ccc; 
}

/* Handle on hover */
.chat-box::-webkit-scrollbar-thumb:hover, .side-tasks-height::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
/*-- chatbox scrollbar ends --*/

.text-small {
  font-size: 11px;
  font-weight: 600;
}

.messages-box,
.chat-box {
  // max-height: 350px;
  // min-height: 180px;
  overflow-y: auto;
  overflow-x: hidden;
}
.bg-very-light
{
  background: #f9f9f9;
  
}
.bg-very-light h6
{
  color: #4b9cd8;
}
.rounded-lg {
  border-radius: 0.5rem;
}

.chat-ui input::placeholder {
  font-size: 0.9rem;
  color: #999;
}
.c-sidebar
{
  width: 200px;
  box-shadow: 0 0 2rem 0 #8898aa26;
  // box-shadow: 0 2px 10px -1px #455a642b;
  // -webkit-box-shadow: 1px 1px 1px 0 rgba(0,0,0,.1);
  // box-shadow: 1px 1px 1px 0 rgba(0,0,0,.1);
  
}
.c-sidebar 
{
  color: #444;
  background: #fff;
}
@media (min-width: 992px)
{
html:not([dir="rtl"]) .c-sidebar.c-sidebar-lg-show:not(.c-sidebar-right).c-sidebar-fixed ~ .c-wrapper, html:not([dir="rtl"]) .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed ~ .c-wrapper {
    margin-left: 200px;
}
}
.c-sidebar .c-sidebar-nav-link, .c-sidebar .c-sidebar-nav-dropdown-toggle
{
  // font-weight: normal;
  font-size: 12px;
  font-weight: 600;
}
// .c-sidebar .c-sidebar-brand {
//   border-bottom: 1px solid #d8dbe0;
//   border-bottom: 1px solid #f3f3f3;
// }
.c-sidebar-brand { 
  flex: 0 0 60px;
}
.c-sidebar .c-sidebar-brand
{
  background: #ffffff;
  
}
.c-header {
 
  min-height: 60px;
  box-shadow: 40px 0 2rem 0 #8898aa26;
}
.outline-btn-width.minimized
{
  display: none;
}
.c-sidebar-minimized .long-btn
{
  display: none;
}
.c-sidebar-minimized .minimized
{
  display: block !important;
}
@media (hover: hover), not all
{
.c-sidebar .c-sidebar-nav-link:hover, .c-sidebar .c-sidebar-nav-dropdown-toggle:hover {
    color: #fff;
    background: #f3f3f3;
    // border-left: 1px solid #ffffffa1;
    box-shadow: inset 3px 0 0 #555;
}
}
@media (max-width: 991.98px)
{
  .mobile-min.c-sidebar.c-sidebar-fixed
  {
    z-index: 1031 !important;
  }
}
.c-sidebar .c-sidebar-nav-link.c-active, .c-sidebar .c-active.c-sidebar-nav-dropdown-toggle
{
  box-shadow: inset 3px 0 0 #555;
  background: #f3f3f3;
  
}

.c-sidebar .c-sidebar-minimizer
{
  background: #fff;
}
.c-sidebar-minimizer::before
{
  background-size: 9.5px;
}
.c-sidebar .c-sidebar-nav-link .c-sidebar-nav-icon, .c-sidebar .c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon
{
  color: #555 !important;
}
.c-sidebar.c-sidebar-minimized .c-sidebar-nav-item:hover > .c-sidebar-nav-link, .c-sidebar.c-sidebar-minimized .c-sidebar-nav-item:hover > .c-sidebar-nav-dropdown-toggle, .c-sidebar.c-sidebar-minimized .c-sidebar-nav > .c-sidebar-nav-dropdown:hover
{
  background: #f3f3f3;
}
.c-sidebar .c-sidebar-nav-link, .c-sidebar .c-sidebar-nav-dropdown-toggle
{
  color: #555 !important;
}
 .c-header .c-header-nav .c-header-nav-link, .c-header .c-header-nav .c-header-nav-btn 
{
  color: #444;
  font-weight: 600;
  font-size: 12px;
}
.c-header-nav {
  min-height: 60px;
}
.c-header-toggler
{
  min-width: 30px;
}
.c-subheader
{
  min-height: 35px;
}
.c-header .c-subheader
{
  border-top: 1px solid #f5f5f5;
}
.c-subheader-nav
{
  min-height: 35px;
}
.light-card-header
{
  // border-bottom: #00c0ef;
  border-bottom: 0;
}
.light-border
{
 border-bottom: 1px solid #f3f3f3;
 border-top: 0;
}
.count-size
{
  font-size: 16px;
  font-weight: 600;
}
.card-style-border-top
{
  border-top: 3px solid #d2d6de;
}
.card-style-border-top-white
{
  border-top: 3px solid #ffffff;
}
.card-style-border-single-top
{
  border-top: 1px solid #d2d6de;
}
.journal-hidden-border-top
{
  border-top: 1px solid #ececec;
}
.white-chips .chip
{
  background: #fff;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 21, 0.075) !important;
}
.btn-label {position: relative;left: -12px;display: inline-block;padding: 6px 12px;background: rgba(0,0,0,0.15);border-radius: 3px 0 0 3px;}
.btn-labeled {padding-top: 0;padding-bottom: 0;}
.input-fields .p-inputtext
{
  border: 0 !important;
}
.card-style-border-left
{
  border-left: 3px solid #d2d6de !important;
}
.left-border-item
{
  border-left: 1px solid #f3f3f3;
}
.left-highlight-card
{
  border-left: .21rem solid #4b9cd8!important;
}
.breadcrumb-item
{
  font-size: 12px;
  font-weight: 500;
}
.breadcrumb-item a:first-child
{
  color: #444 !important
}
.breadcrumb-item a:before
{
  
  font-family: 'Font Awesome 5 Free';
  content: "\f015";
  padding-right: 5px;
  font-size: 12px;
  font-weight: 600;
  color: #444;
  
}
.head-border-bottom
{
  border-bottom: 2px solid #eee;
}
.link-anchor, .head-bold, .queries-list-font h6
{
  font-weight: 600;
}
.text-info
{
  color: #4b9cd8 !important;
}
.badge-info
{
  background-color: #4b9cd8 !important;
}
.min-font-size
{
  font-size: 12px;
}
.queries-list-font p, .queries-list-font small
{
  font-weight: 500;
}
.date-font-feedback
{
  font-size: 65%;
  font-weight: 600;
}
.p-component
{
  font-family: 'Montserrat', sans-serif;
}
.p-datatable .p-datatable-tbody tr td
{
  font-weight: 600;
  font-size: 10px;
}
.btn-shadow
{
  -webkit-box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
  box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
}
.btn-xs
{
  padding: 1px 5px!important;
  // font-size: 12px!important;
  // line-height: 1.5!important;
  border-radius: 2px!important;
}
.input-fields .p-inputtext, .input-fields .p-dropdown
{
  font-size: .8125rem!important;
  border: 1px solid #e8ecec!important;
  padding: 0.259rem 0.429rem;
  font-weight: 500;
  width: 100%;
}
.p-input-icon-left, .p-input-icon-right {
  position: relative;
  display: block;
}
.no-input-text .p-inputtext
{
  border: 0 !important;
  padding: 0 !important;
}
.btn-info-line
{
  line-height: 1.559;
}
.btn-info
{
  background-color: #4b9cd8 !important;
  border-color: #4b9cd8 !important; 
}
.input-fields .p-dropdown-trigger-icon.pi.pi-chevron-down.p-clickable
{
  font-size: 12px;
}
.input-fields .p-dropdown-panel .p-dropdown-items .p-dropdown-item, .less-bold-label
{
  font-size: 12px;
  font-weight: 500;
}
.c-sidebar.c-sidebar-minimized .c-sidebar-nav > .c-sidebar-nav-dropdown > .c-sidebar-nav-dropdown-items
{
  background: #f3f3f3;
}
.c-sidebar .c-sidebar-nav-dropdown.c-show
{
  background: #f3f3f3;
}
.p-paginator .p-paginator-current
{
  font-size: 12px;
  font-weight: 500;
  color: #777;
}
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: #4b9cd8!important;
  border-color: #4b9cd8!important;
  color: #fff;
}
.p-paginator .p-paginator-pages .p-paginator-page
{
  font-size: 12px;
  font-weight: 500;
  color: #555;
  border-radius: 50px;
}
// .p-paginator .p-paginator-pages .p-paginator-page.p-highlight
// {
//   -webkit-box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
//   box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
// }
.p-datatable .p-paginator-bottom
{
  padding: 8px;
  margin-top: 20px;
  border: 0 !important;
}
.p-paginator .p-dropdown
{
  height: 1.75rem;
}
.p-paginator .p-dropdown .p-dropdown-label,  .table-inside
{
  font-size: 12px;
  font-weight: 500;
}
.c-footer
{
  background: #f3f3f3;
  font-weight: 600;
  font-size: 11px;
  
}
.p-paginator .p-dropdown-trigger-icon.pi.pi-chevron-down.p-clickable, .min-font-size
{
  font-size: 12px;
}
.p-paginator .p-inputtext
{
  padding: 0.219rem 0.429rem;
  color: #555;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
}
.bold-label
{
  font-weight: 600;
}
.credit-debit-table th, .credit-debit-table td
{
  padding: 0.45rem;
} 

.active-chips .chips-label, .groups-chips .chips-label
{
  font-size: 12px;
  font-weight: 600;
  
}
.groups-chips .chip
{
  display: inline-block;
    padding: 0 25px;
    height: 35px;
    font-size: 16px;
    line-height: 35px;
    border-radius: 25px;
    background-color: #f1f1f1;
    cursor: pointer;
}

.tabs-with-pills .p-tabview-nav li .p-tabview-nav-link
{
    display: inline-block;
    padding: 0 25px;
    height: 35px;
    font-size: 12px;
    line-height: 35px;
    border-radius: 25px;
    background-color: #fff;
    color: #777;
    font-weight: 600;
    -webkit-box-shadow: 0 10px 20px 0 rgba(0,0,0,.05);
    box-shadow: 0 10px 20px 0 rgba(0,0,0,.05);
    margin-right: 10px !important;
    cursor: pointer;
}

.tabs-with-pills .p-tabview-panels
{
  background: transparent !important;
  border: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 10px;
}
.tabs-with-pills .p-tabview-nav li.p-highlight .p-tabview-nav-link
{
    color: #fff !important;
}
.add-form-pills .p-tabview-nav li .p-tabview-nav-link {
  margin: inherit;
  border: 1px solid #bdbdbd !important;
  border-width: 1px;
  border-radius: 2px;
}
.add-form-pills .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  color: #666 !important;
  background: #ccc !important;
}
.active-chips .chip, .groups-chips .chip
{
  line-height: 34px !important;
  color: #777;
}
.active-chips .chip.active, .groups-chips .chip.active
{
  background-color: #4B9CD8 !important;
  color: #fff; 
}
.tabbed-nav-container-default .p-tabview-nav:after{
  position: inherit !important;
}
.form-control:disabled, .form-control[readonly]
{
  background-color: #e8ecec;
}
.tabbed-nav-container-default .p-tabview-nav:after{
  content: "";
  width: 100%;
  height: 2px;
  display: block;
  border-radius: 3px 3px 0 0;
  position: absolute;
  bottom: -2px;
  background: #f3f3f3;
}
.tabs-material .p-tabview-panels
{
  margin-top: 10px;
  padding: 0;
  border: 0;
}
.tabs-material .p-tabview-nav li .p-tabview-nav-link
{
  padding: 0.557rem 1rem !important;
  font-weight: 600 !important;
  font-size: 12px;
  color: #777;
}
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link
{
  background: #4B9CD8;
    border-color: #4B9CD8;
}
.bg-info
{
  background-color: #4b9cd8 !important;
    border-color: #4b9cd8 !important;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link
{
  
  margin: inherit;
  border-color: #fff;
  border: 0;
}
.min-heading-text
{
  font-size: 12px;
  color: #777;
  font-weight: 600;
}
.query-add-chips .MuiChip-root
{
  height: auto;
}
.query-add-chips .MuiChip-deleteIcon
{
  height: 15px;
}
.query-add-chips .MuiChip-label
{
  font-size: 11px;
}
.c-pointer
{
  cursor: pointer;
}

.rounded-btns-header .btn-circle {
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 6px 0;
  font-size: 12px;
  line-height: 1.428571429;
  border-radius: 15px;
}
.rounded-btns-header .btn-circle.btn-lg {
  width: 30px;
  height: 30px;
  padding: 5px 5px;
  font-size: 12px;
  line-height: 1.33;
  border-radius: 25px;
}
.border-radius-25
{
  border-radius: 25px;
}
.very-muted-text
{
  color: #f3f3f3 !important;
}
.btn-primary
{
  background-color: #4b9cd8!important;
  border-color: #4b9cd8!important;
}

.modal-header button.close
{
  opacity: 1;
  color: #777;
  font-weight: 600;
  font-size: 25px;
}
.modal-dark .modal-header {
  color: #777;
  background-color: #fff;
  font-weight: 600;
  
}
.modal-dark .modal-header.query-modal-header {
  color: #777;
  background-color: transparent;
  font-weight: 600;
  
}
.modal-dark .modal-header .modal-title , .modal-header .modal-title
{
  font-weight: 600;
}
.card-body-scroll
{
  height: 200px;
  overflow: auto;
}
.thead-light th
{
  color: #777 !important;
  background-color: #f3f3f3 !important;
}

.form-control 
{
    font-size: .8125rem!important;
    border: 1px solid #ddd;
    border-radius: 2px;
    
}

.MuiOutlinedInput-notchedOutline
{
  border: 1px solid #ddd!important;
  border-radius: 2px;
}

.left-highlight-card-success
{
  border-left: 0.21rem solid #2eb85c !important;
}
.hover-box:hover
{
  background: #f9f9f9;
}
.inside-nested-query-modal .modal-lg
{
  max-width: 800px !important;
  margin: 1.75rem auto !important;
  position: relative !important;
}
.inside-nested-query-modal .modal-body
{
  max-height: initial;
  overflow-y: auto;
}
.inside-nested-query-modal .modal-content
{
  height: auto !important;
}
.inside-nested-query-modal .modal-header button.close
{
  margin-top: 5px;
  font-size: x-large !important;
  margin-left: -32px;
  margin: inherit !important;
  padding: 0;  
}  
.inside-nested-query-modal .modal
{
  background: #00000091 !important;;
}
.hover-card .card:hover
{
    -webkit-box-shadow: 0 10px 20px 0 rgba(0,0,0,.05);
    box-shadow: 0 10px 20px 0 rgba(0,0,0,.05);
    border-top: 3px solid #d2d6de !important;
    cursor: pointer;
}
.hover-card .card
{
-webkit-transition: all 250ms cubic-bezier(.02, .01, .47, 1);
    -moz-transition: all 250ms cubic-bezier(.02, .01, .47, 1);
    transition: all 250ms cubic-bezier(.02, .01, .47, 1);
}
.small-status
{
  font-size: 10px;
  font-weight: 600;
}
.c-header
{
  // background: #f3f3f3;
  background: #fff;
  // -webkit-box-shadow: 0 1px 1px 0 rgba(0,0,0,.1);
  // box-shadow: 0 1px 1px 0 rgba(0,0,0,.1);
  
}
// .c-header-with-subheader
// {
//    box-shadow: 0 2px 7px 0 rgba(0,0,0,.07);
//   box-shadow: 0 2px 3px -1px rgba(0,0,0,.07);
 
// }
.total-count-text-info
{
  font-weight: 500;
  color: #888888;
}
.query-view-accordion .p-accordion-content
{
  border: 0 !important;
  padding-left: 0;
  padding-right: 0; 
}
.p-accordion.query-view-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
  border-color: #ffffff;
  background: #ffffff;
  color: #777;
  box-shadow: none;
}
.p-accordion.query-view-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
  background: #fff;
  border-color: #fff;
  color: #777;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  padding-left: 0;
}
.p-accordion.query-view-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link {
  background: #ffff;
  border-color: #ffff;
  color: #777;
}
.p-accordion.query-view-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.p-accordion.query-view-accordion .p-accordion-header .p-accordion-header-link
{
  padding-left: 0;
    border: 1px solid #fff;
    color: #dc3545!important;
    background: #fff;
    font-weight: 600;
    border-radius: 0px;
}
.query-view-accordion .p-accordion-header a:hover
{
  color: #444;
}
.first-empty-icon
{
  font-size: 80px;
  color: #ccc;
 
}
.forgot-empty-icon
{
  font-size: 80px;
  color: #777;
 
}
.fade-text
{
  color: #ccc;
}
.min-less-font
{
  font-size: 10px;
}
.c-sidebar .c-sidebar-minimizer
{
  border-right: 1px solid #d8d8d817;
}
.c-sidebar .c-sidebar-minimizer:hover
{
  background: #f3f3f3;
  
}
.c-sidebar .c-sidebar-nav-dropdown-toggle::after {
  // background-image: url(data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='rgba(255, 255, 255, 0.5)' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E);
  background-image: none !important;
  
  font-family: 'Font Awesome 5 Free';
  content: "\f107";
  padding-right: 0px;
  padding-top: -4px;
}

.c-sidebar-nav-dropdown-toggle::after
{
  margin-top: -10px;
}
.c-sidebar .c-sidebar-minimizer::before {
  background-image: none;
  font-family: 'Font Awesome 5 Free';
  content: "\f104";
  padding-right: 0px;
  font-size: 21px;
  font-weight: 600;
  color: #555;
  padding-top: -4px;
}
.c-sidebar-minimizer::before
{
  width: 30px;
  height: 40px;
  margin-top: 15px;
}
.max-rounded
{
  border-radius: 25px;
}
.rounded-btn-xs
{
  border-radius: 50px !important;
}
.n-h-w
{
  width: inherit !important;
  height: inherit !important;
  margin: inherit !important;
}
.rounded-cap
{
  width: 38px;
    height: 38px;
    border-radius: 50px;
    line-height: 3;
    margin-top: -2px;
    font-size: 10px;
    font-weight: 600;
}
.normal-demo-text
{
  color: #777;
}
.form-control:focus, textarea:focus, select:focus, button:focus
{
  outline: none;
  box-shadow: none;
  
}
.chat-none-border:focus
{
  outline: none;
  box-shadow: none;
  border: 0;
}
.side-tasks-height
{
  // max-height: 520px;
  overflow: auto;
}

.login-shadow-with-border
{
  // box-shadow: 0 3px 20px 0 rgba(0,0,0,.1);
  -moz-box-shadow: 0 10px 20px 0 rgba(0,0,0,.05);
  // -webkit-box-shadow: 0 3px 20px 0 rgba(0,0,0,.1);
  -o-box-shadow: 0 10px 20px 0 rgba(0,0,0,.05);
  -ms-box-shadow: 0 10px 20px 0 rgba(0,0,0,.05);
  -webkit-box-shadow: 0 10px 20px 0 rgba(0,0,0,.05);
  box-shadow: 0 10px 20px 0 rgba(0,0,0,.05);
  border-radius: 20px !important;
}
/*----socail media --------*/

.rounded-social-buttons {
  text-align: center;
}

.rounded-social-buttons .social-button {
  display: inline-block;
  position: relative;
  cursor: pointer;
  width: 3.125rem;
  height: 3.125rem;
  border: 0.125rem solid transparent;
  padding: 0;
  text-decoration: none;
  text-align: center;
  color: #fefefe;
  font-size: 1.5625rem;
  font-weight: normal;
  line-height: 2em;
  border-radius: 1.6875rem;
  transition: all 0.5s ease;
  margin-right: 0.25rem;
  margin-bottom: 0.25rem;
}

.rounded-social-buttons .social-button:hover, .rounded-social-buttons .social-button:focus {
  -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
          transform: rotate(360deg);
}

.rounded-social-buttons .fa-twitter, .fa-facebook-f, .fa-linkedin, .fa-youtube, .fa-instagram {
  font-size: 25px;
}

.rounded-social-buttons .social-button.facebook {
  background: #3b5998;
}

.rounded-social-buttons .social-button.facebook:hover, .rounded-social-buttons .social-button.facebook:focus {
  color: #3b5998;
  background: #fefefe;
  border-color: #3b5998;
}

.rounded-social-buttons .social-button.twitter {
  background: #55acee;
}

.rounded-social-buttons .social-button.twitter:hover, .rounded-social-buttons .social-button.twitter:focus {
  color: #55acee;
  background: #fefefe;
  border-color: #55acee;
}

.rounded-social-buttons .social-button.linkedin {
  background: #007bb5;
}

.rounded-social-buttons .social-button.linkedin:hover, .rounded-social-buttons .social-button.linkedin:focus {
  color: #007bb5;
  background: #fefefe;
  border-color: #007bb5;
}

.rounded-social-buttons .social-button.youtube {
  background: #bb0000;
}

.rounded-social-buttons .social-button.youtube:hover, .rounded-social-buttons .social-button.youtube:focus {
  color: #bb0000;
  background: #fefefe;
  border-color: #bb0000;
}

.rounded-social-buttons .social-button.instagram {
  background: #125688;
}

.rounded-social-buttons .social-button.instagram:hover, .rounded-social-buttons .social-button.instagram:focus {
  color: #125688;
  background: #fefefe;
  border-color: #125688;
}
/*----socail media ends--------*/
.p-datatable .p-sortable-column:not(.p-highlight):hover
{
  background: #f3f3f3;
}
.p-datatable .p-sortable-column.p-highlight, .p-datatable .p-sortable-column.p-highlight:hover
{
  background: #f3f3f3;
  color: #333333;
}
.p-datatable .p-sortable-column.p-highlight:hover .p-sortable-column-icon,.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon
{
  color: #333;
}
.inside-border-radius
{
  border-radius: 10px !important;
}
.custom-input
{
  height: 45px;
}
.text-primary
{
  color: #4b9cd8!important;
}
.bg-primary
{
  background-color: #4b9cd8;
}
.hr-light-border
{
  border-top: 1px solid #f7f7f7;
}
.right-line-border
{
  border-right: 1px solid #f3f3f3;
}
.bottom-line-border
{
  border-bottom: 1px solid #f3f3f3;
}
.query-close-btn.modal-header button.close
{
  margin-top: 5px;
  font-size: 34px;
  margin-left: -32px;
  margin: 6px 0rem -2rem -4px;
  padding: 0;
}
.sign-error-msgs
{
  font-size: 10px;
  font-weight: 500;
  text-align: center;
}
.inside-icon
{
  position: absolute;
    color: #aaa;
    top: 16px;
    right: 10px;
    font-size: 12px;
    z-index: 1111;
}
.search-inside-icon
{
    position: absolute;
    color: #666;
    top: 25px;
    left: 10px;
    font-size: 12px;
    z-index: 1111;
}
.inside-icon-change
{
  position: absolute;
    color: #aaa;
    top: 40px;
    right: 30px;
    font-size: 12px;
    z-index: 1111;
}
.large-font
{
  font-size: 80px;
  color: #ccc;
}
.fade-bold
{
  font-size: 12px;
}
.large-double
{
  font-size: 70px;
  color: #ccc;
  
}
.fade-bold
{
  font-size: 12px;
}
.font-size-12
{
  font-size: 12px;
}
.font-size-10
{
  font-size: 10px;
}
.large-empty-height
{
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
}
 
 .p-paginator .p-paginator-current {
  margin-left: auto;
}
.p-paginator .p-dropdown-panel .p-dropdown-items .p-dropdown-item
{
  font-size: 10px;
  font-weight: 500;
}
th
{
  border: 1px solid #f4f4f4 !important;
  color: #777;
}
td
{
  border: 1px solid #f3f3f3 !important;
  color: #777;
}
.p-paginator
{
  border: 1px solid #f3f3f3 !important;
  background: #fff;
}

/* Left to right Popup */
.modal-fullscreen-xl .modal-dialog{
  position: fixed;
  margin: auto;
  max-width: 100%;
  width: 100%;
  height: 100%;
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
  -webkit-font-smoothing: antialiased;
}

.modal-fullscreen-xl .modal-content{
  height: 100%;
  overflow-y: auto;
  border-width: 0;
  border-radius: 0;
}

.modal-fullscreen-xl .modal-body{
  padding: 15px 15px;
  max-height: calc(100vh - 46px);
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}

/*Left*/
.modal-fullscreen-xl .modal-dialog {
  left: -90%;
  -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
  -o-transition: opacity 0.3s linear, left 0.3s ease-out;
  transition: opacity 0.3s linear, left 0.3s ease-out;
}

.modal-fullscreen-xl .modal-dialog {
  left: 0;
}
.modal-fullscreen-xl.query-view-modal .modal-content{
  margin-left: 7px;
  margin-right: 7px;
  height: 97%;
}
.modal-fullscreen-xl.query-view-modal .modal-body
{
  max-height: inherit;
  overflow-y: hidden;
}
.modal-inside-query-height
{
  height: 100vh;
  overflow-y: auto;
}
.left-modal-inside-query-height
{
  height: 90vh;
  overflow-y: auto;
}
.d-content-100
{
  // display: contents;
  width: 100%;
}
.less-modal-bold label
{
  font-weight: 500;
  font-size: 12px;
 
}
.bg-light-header
{
  background: #f3f3f3;
}

.btn-file {
  position: relative;
  overflow: hidden;
}
.btn-file input[type=file] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
}
.p-datepicker table th
{
    padding: 1px;
    font-size: 12px;
    text-align: center;
    border-width: 0 !important;
}
.p-datepicker table td {
  padding: 1px;
  font-size: 12px;
  font-weight: 500;
  border-width: 0 !important;
}
.p-datepicker
{
  padding: 0;
}
.p-datepicker table td.p-datepicker-today > span {
  background: #4f99ff;
  border-radius: 50%;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  border-color: transparent;
}
.p-datepicker table td > span, .icon-with-radius
{
  border-radius: 50%
  
}
/*-- tree table --*/
.balance-sheet-tree .p-treetable .p-treetable-tbody > tr > td
{
  font-weight: 500;
  color: #555;
  font-size: 12px;
  padding: 5px;
}
.balance-sheet-tree .p-treetable .p-treetable-tbody > tr > td:last-child {
  text-align: right;
}
.balance-sheet-tree .p-treetable .p-treetable-thead > tr > th:last-child {
  text-align: right;
}
.balance-sheet-tree .p-treetable .p-treetable-thead > tr > th
{
  font-weight: 600;
  padding: 5px;
  font-size: 14px;
  color: #777;
}

.budget-tree-modal .p-treetable .p-treetable-tbody > tr > td, .budget-tree-modal-a-vs-b .p-treetable .p-treetable-tbody > tr > td
{
  font-weight: 500;
  color: #777;
  border-width: 0 0 1px 0 !important;
  font-size: 12px;
}

.budget-tree-modal .p-treetable .p-treetable-thead > tr > th
{
  font-weight: 500 !important;
  font-size: 14px;
  color: #777;
  border-width: 1px 0 1px 0 !important;
  background-color: #fff;
}

.budget-tree-modal .p-treetable .p-treetable-thead > tr > th
{
  border-top: 0 !important;
}

.budget-tree-modal .p-treetable .p-treetable-thead > tr > th
{
  border-color: #f9f9f9 !important;
}
.budget-tree-modal .p-treetable .p-treetable-thead > tr > th:first-child, .budget-tree-modal .p-treetable .p-treetable-tbody > tr > td:first-child
{
  border-color: #f9f9f9 !important;
}
.budget-tree-modal .p-treetable .p-treetable-tbody > tr > td
{
  border-color: #f9f9f9 !important;
}
.budget-tree-modal .p-treetable .p-treetable-tbody > tr:last-child > td
{
  border-width: 0 !important;
}
.budget-tree-modal .p-treetable .p-treetable-tbody > tr:first-child > td
{
  border-top: 0 !important;
}
.budget-tree-modal .p-treetable-scrollable-body-table
{
  margin-bottom: 10px;
}
/*budget-actual*/
.budget-vs-actual .p-treetable .p-treetable-tbody > tr > td, .budget-tree-modal-a-vs-b .p-treetable .p-treetable-tbody > tr > td
{
  font-weight: 500;
  color: #777;
  border-width: 0 0 1px 0 !important;
  font-size: 12px;
}

.budget-vs-actual .p-treetable .p-treetable-thead > tr > th
{
  font-weight: 500 !important;
  font-size: 14px;
  color: #777;
  border-width: 1 !important;
  background-color: #fff;
}

.budget-vs-actual .p-treetable .p-treetable-thead > tr > th
{
  border-top: 1 !important;
}

.budget-vs-actual .p-treetable .p-treetable-thead > tr > th
{
  border-color: #f9f9f9 !important;
}
.budget-vs-actual .p-treetable .p-treetable-thead > tr > th:first-child, .budget-tree-modal .p-treetable .p-treetable-tbody > tr > td:first-child
{
  border-color: #f9f9f9 !important;
}
.budget-vs-actual .p-treetable .p-treetable-tbody > tr > td
{
  border-color: #f9f9f9 !important;
}
.budget-vs-actual .p-treetable .p-treetable-tbody > tr:last-child > td
{
  border-width: 0 !important;
}
.budget-vs-actual .p-treetable .p-treetable-tbody > tr:first-child > td
{
  border-top: 0 !important;
}
.budget-vs-actual .p-treetable-scrollable-body-table
{
  margin-bottom: 10px;
}
/* budget vs actual */
.budget-hover-btns
{
  border-width: 2px;
}
.budget-hover-btns:hover
{
  border-color: #4b9cd8;
  background-color: #4B9CD8;

}
.table-fixed thead {
  width: 100%;
}
.table-fixed tbody {
  max-height: 180px;
  overflow-y: auto;
  width: 100%;
}
.table-fixed thead, .table-fixed tbody, .table-fixed tr, .table-fixed td, .table-fixed th {
  display: block;
}
.table-fixed tbody td, .table-fixed thead > tr> th {
  float: left;
  border-bottom-width: 0;
}
.budget-tree-modal .treetable-head .pi-fw, .budget-vs-actual .p-treetable-tbody .pi-fw
{
  font-size: 12px;
}
// .budget-tree-modal-projection .p-treetable .p-treetable-thead > tr > th:first-child, .budget-tree-modal-projection .p-treetable .p-treetable-tbody > tr > td:first-child
// {
  
//   background-color: #f9f9f9 !important;
// }

.budget-tree-modal .p-treetable .budget-head
{
  font-weight: 600;
}
.balance-sheet-tree .pi-fw
{
  font-size: 12px;
}
.tree-no-border .p-treetable .p-treetable-tbody > tr > td, .tree-no-border .p-treetable .p-treetable-thead > tr > th
{
  border-width: 0 !important;
}
.tree-no-border .p-treetable .p-treetable-thead > tr > th
{ 
  background: #fff !important;
}
.tree-no-border .p-treetable .p-treetable-thead
{
  border-top: 1px solid #ececec;
  border-bottom: 1px solid #ececec;
}

.tree-no-border-modal .p-treetable .p-treetable-tbody > tr > td, .tree-no-border-modal .p-treetable .p-treetable-thead > tr > th
{
  border-width: 0 !important;
}
.tree-no-border-modal .p-treetable .p-treetable-thead > tr > th
{ 
  background: #f9f9f9 !important;
}
.tree-no-border-modal .p-treetable .p-treetable-thead
{
  border-top: 1px solid #f9f9f9;
  border-bottom: 1px solid #f9f9f9;
}
.tree-no-border-modal .p-treetable .p-treetable-thead > tr > th {
  font-weight: 500 !important;
  padding: 10px;
  font-size: 14px;
  color: #777;
}
.mdb-shadow
{
  -webkit-box-shadow: 0 10px 20px 0 rgba(0,0,0,.05);
  box-shadow: 0 10px 20px 0 rgba(0,0,0,.05);
}
// .shadow-for-google button
// {
//   -webkit-box-shadow: 0 10px 20px 0 rgba(0,0,0,.05) !important;
//   box-shadow: 0 10px 20px 0 rgba(0,0,0,.05) !important;
// }
.shadow-for-google span
{
  font-weight: 600 !important;
}
/*--- tree table ends here --*/
/*-- ribbons --*/

.ribbon {
  
  position: relative;
 
}

.ribbon3 {
  width: 150px;
  height: 35px;
  font-size: 12px;
  line-height: 37px;
  color: #fff;
  font-weight: 600;
  padding-left: 15px;
  position: absolute;
  left: -9px;
  top: 27px;
 
}
.ribbon3:before, .ribbon3:after {
  content: "";
  position: absolute;
}
.ribbon3:before {
  height: 0;
  width: 0;
  top: -8.1px;
  left: 0.1px;
  border-bottom: 8px solid #4b9cd8;
  border-left: 9px solid transparent;
}
.ribbon-success:before {
  
  border-bottom: 8px solid #2eb85c !important
 
}
.icon-with-tab .p-tabview-title
{
  margin-left: 5px;
}
.bg-primary
{
  background: #4b9cd8;
  background-color: #4b9cd8 !important;
}
/*-- ribbon ends here --*/  
.border-left-right-bottom-0
{
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
}
.c-body
{
  background: #f3f3f3;
}

/*CSS ALL*/
.checkbox,
.radio {
  display: inline-block;
  margin-bottom: 15px; }
  .checkbox:hover,
  .radio:hover {
    cursor: pointer; }
  .checkbox .fa,
  .radio .fa {
    width: 1em; }



.checkbox input[type="checkbox"],
.radio input[type="radio"] {
  display: none; }
  .checkbox input[type="checkbox"] + i:before,
  .radio input[type="radio"] + i:before {
    content: "\f096";
    position: relative;
    bottom: -4px;
    margin-right: 5px;
    color: #e6ecec; }

.checkbox:hover input[type="checkbox"] + i:before,
.radio:hover input[type="radio"] + i:before {
  color: #007bff!important; }

.checkbox input[type="checkbox"]:checked + i:before,
.radio input[type="radio"]:checked + i:before {
  content: "\f046";
  color: #007bff!important; }

.checkbox input[type="checkbox"]:disabled + i:before,
.checkbox input[type="checkbox"]:disabled:checked + i:before,
.radio input[type="radio"]:disabled + i:before,
.radio input[type="radio"]:disabled:checked + i:before {
  color: #ddd; }

/*RADIO*/
.radio input[type="radio"] + i:before {
  content: "\f1db"; }

.radio input[type="radio"]:checked + i:before {
  content: "\f058"; }

.p-treetable-tbody .p-treetable-emptymessage
{
    text-align: center !important;
    padding-top: 10% !important;
    padding-bottom: 10% !important;
    font-size: 18px !important;
    color: #ccc !important;
}
.hr-border-color
{
  border-width: 2px;
  border-color: #e9ecef;
}
.autocomplete-input
{
  font-size: 14px;
  font-weight: 500;
  color: #768192;
}
.no-p-cal .p-calendar
{
  display: initial;
}
.group-search-tree .p-treetable .p-treetable-header
{
  padding-top: 5px;
  padding-bottom: 5px;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  background: #fff;
  margin-bottom: 5px;
}
.group-search-tree .p-treetable .p-treetable-thead > tr > th
{
  font-size: 14px;
  font-weight: 600;
  color: #555;
}
.group-search-tree .p-treetable .p-treetable-tbody > tr > td
{
  font-weight: 500;
  font-size: 12px;
}
.cardbody-scroll
{
  height: 136px;
}
.outline-btn-width
{
  border-width: 2px;
}
.rounded-pdf button.btn-success
{
    width: 30px;
    height: 30px;
    padding: 5px 5px;
    font-size: 12px;
    line-height: 1.33;
    border-radius: 25px;
}

.btn-first
{
  border-right: 0;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}
.btn-second
{
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}
.left-border-whit
{
  border-left: 1px solid #fff !important;
}
.dark-th-border th
{
  border-top: 0 !important;
  border-right: 0 !important;

}
.light-td-border td
{
  border-bottom: 0 !important;
  border-left: 1px dotted #d8dbe0 !important;
  border-right: 0 !important;
}
.light-td-border>tbody>tr:last-child>td
{
  border-bottom: 2px solid #d8dbe0 !important;
}
.light-td-border-thin tbody tr:last-child td
{
  border-bottom: 1px solid #d8dbe0 !important;
}
.p-datepicker-calendar tbody tr:last-child td
{
  border-bottom: 0px solid #d8dbe0 !important;
}
.light-td-border td:first-child , .light-td-border th:first-child
{
  border-left: 0 !important;
 
}
.light-td-border td:last-child, .light-td-border th:last-child
{
  border-right: 0 !important;
}
.left-modal .modal-dialog
{
  margin: 0 1.75rem auto;
}
.p-datatable .p-datatable-wrapper
{
  width: 100%;
  display: block;
  overflow-x: auto;
}
/*--left --*/

.left-modal .modal .modal-dialog
 {
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}

/* Left & Right */

// .left-modal .modal.fade .modal-dialog {
//   left: -500px;
//   -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
//   -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
//   -o-transition: opacity 0.3s linear, left 0.3s ease-out;
//   transition: opacity 0.3s linear, left 0.3s ease-out;
// }

// .left-modal .modal.fade.show .modal-dialog {
//   left: 0;
// }

/*-- left ends here --*/
.attachment-inside aside h4
{
  font-size: 14px;
  margin-top: 10px;
}
.attachment-inside aside ul
{
  padding-left: 0;
}
.attachment-inside aside ul li
{
  font-size: 12px;
  font-weight: 500;
  list-style: none;
}
.modal-dark .modal-content
{
  border-color: transparent;
}
.nested-modal .modal
{
  background: #000000b5;
}
.attachment-inside aside ul li:before
{
  
  font-family: 'Font Awesome 5 Free';
  content: "\f00c";
  padding-right: 5px;
  font-size: 12px;
  font-weight: 600;
  color: #444;
  
}
.left-modal .modal .modal-content:before 
{
  content: "";
  position: absolute;
  top: 3px;
  left: -0px;
  border-top: 5px solid #fff;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  transform: rotate(-180deg);
  transform-origin: 75% -23% 0;
}
// .dropdown-menu:before
// {
//   content: "";
//   position: absolute;
//   top: 5px;
//   left: 88%;
//   border-top: 7px solid #fff;
//   border-left: 7px solid transparent;
//   border-right: 7px solid transparent;
//   transform: rotate(-180deg);
//   transform-origin: 75% -23% 0;
// }
// th.p-sortable-column.text-right
// {
//   text-align: left !important;
// }
// No media query necessary for xs breakpoint as it's effectively `@media (min-width: 0) { ... }`
.no-card-bg
{
  background: transparent;
}
@include media-breakpoint-down(xs) 
{

  .tags-labels-scroll
  {
    height: auto !important;
  }
  .ledger-budgeting-height
  {
    height: auto !important;
  }
  .top-header-dropdown-menu .dropdown-menu {
    width: 225px !important;
}
  .card-columns
  {
    -webkit-column-count: 1 !important;
    column-count: 1 !important;
  }
  .mobile-enable
  {
    display: block;
  }
  .modal-fullscreen-xl-purchase .modal-dialog
  {
    width: 100% !important;
  }
  .mobile-main-space-pad
  {
      padding: 15px !important;
  }
  .mobile-pad-right-0
  {
    padding-right: 0 !important;
  }
  .mobile-margin-right-0
  {
    margin-right: 0 !important;
  }
  .mobile-google span:nth-of-type(1)
  {
    display: none;
  }
  .mobile-google
  {
    background-color: rgb(221, 75, 57) !important;
    display: inline-block;
    position: relative;
    cursor: pointer;
    width: 3.125rem;
    height: 3.125rem;
    border: 0.125rem solid transparent;
    padding: 0;
    text-decoration: none;
    text-align: center;
    color: #fefefe;
    font-size: 1.5625rem;
    font-weight: normal;
    border-radius: 1.6875rem !important;
    transition: all 0.5s ease;
    margin-right: 0.25rem;
    margin-bottom: 0.25rem;
  }
  .mobile-google div:nth-of-type(1)
  {
    padding: 15px !important;
    background-color: transparent !important;
  }
  .mobile-google div:nth-of-type(1) svg
  {
    display: none;
  }
  .mobile-google div:nth-of-type(1):after
  {
    font-family: FontAwesome;
    content: "\f1a0";
    color: #fff;
    font-size: 25px;
  }  
  .mobile-no-pad-left
  {
    padding-left: 15px !important;
  }
  .new-close-button.modal-header button.close
  {
    right: 5px !important;
    top: 8px !important;
  }
  .mobile-fixed-top
  {
    position: relative;
  }
  .right-side .modal.right .modal-dialog
  {
    width: auto !important;
  }
  .rightside-medium-modal .modal.right .modal-dialog
  {
    width: auto !important;
  }
  .mobile-logo-width
  {
    width: 200px;
  }
  .right-line-border
  {
    border-right: 0;
  }
  .ribbon3
  {
    display: none;
  }
  .p-paginator .p-paginator-current
  {
    margin-left: inherit;
  }
  .p-datatable table
  {
    table-layout: auto !important;
  }
  .align-text-center
  {
    text-align: center !important;
  }
  .mobile-elipsis
  {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
  .mobile-bottom
  {
    margin-bottom: 5px;
    
  }
  .mobile-pull-left
  {
    float: left !important;
    margin-left: 15px  !important;
    margin-top: 10px !important;
  }
  .p-tabview.tab-view-line .p-tabview-nav li .p-tabview-nav-link
  {
    background: #f4f4f4 !important;
  }
  .p-tabview.tab-view-line .p-tabview-nav li .p-tabview-nav-link
  {
    padding-right: 5px !important;
  }
  .tabs-with-pills .p-tabview-nav li .p-tabview-nav-link
  {
    width: 100%;
    text-align: center;
  }
  .p-tabview .p-tabview-nav li {
    margin-right: 0px;
    width: 100%;
    margin-bottom: 2px;
}
  .active-chips .chip, .groups-chips .chip
  {
    margin-bottom: 5px;
    width: 100%;
    text-align: center;
  }
  .mobile-main-space
  {
    padding: 15px;
  }
  .mobile-padding
  {
      padding: 0 !important;
  }
  .mobile-margin
  {
      margin: 0 !important;
  }
  .mobile-text-cross
  {
    font-size: 20px;
  }
  .vertical-spacer
  {
    display: none;
  }
  .mobile-text-left
  {
    text-align: left !important;
  }
  .mobile-text-center
  {
    text-align: center !important;
  }
  .mobile-block
  {
    display: block !important;
  }
  .mobile-shadow
  {
    box-shadow: none;
  }
  .notify-modal .modal.right .modal-content
  {
    margin-left: 10px !important;
  }
}
@include media-breakpoint-down(sm) 
{ 
  .card-columns
  {
    -webkit-column-count: 1 !important;
    column-count: 1 !important;
  }
  .ledger-budgeting-height
  {
    height: auto !important;
  }
  .align-text-right  
  {
    text-align: right;
  }
  .modal-fullscreen-xl-purchase .modal-dialog
  {
    width: 98% !important;
  }
  .mobile-bottom
  {
    margin-bottom: 5px;
    
  }
  .mobile-main-space-pad
  {
      padding: 15px !important;
  }
  .mobile-enable
  {
    display: block;
  }
  .mobile-pad-right-0
  {
    padding-right: 0 !important;
  }
  .vertical-spacer
  {
    display: none;
  }
  .mobile-block
  {
    display: block !important;
  }
  .mobile-google span:nth-of-type(1)
  {
    display: none;
  }
  .mobile-google
  {
    background-color: rgb(221, 75, 57) !important;
    display: inline-block;
    position: relative;
    cursor: pointer;
    width: 3.125rem;
    height: 3.125rem;
    border: 0.125rem solid transparent;
    padding: 0;
    text-decoration: none;
    text-align: center;
    color: #fefefe;
    font-size: 1.5625rem;
    font-weight: normal;
    border-radius: 1.6875rem !important;
    transition: all 0.5s ease;
    margin-right: 0.25rem;
    margin-bottom: 0.25rem;
  }
  .mobile-google div:nth-of-type(1)
  {
    padding: 15px !important;
    background-color: transparent !important;
  }
  .mobile-google div:nth-of-type(1) svg
  {
    display: none;
  }
  .mobile-google div:nth-of-type(1):after
  {
    font-family: FontAwesome;
    content: "\f1a0";
    color: #fff;
    font-size: 25px;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .card-columns
  {
    -webkit-column-count: 1 !important;
    column-count: 1 !important;
  }
  .ledger-budgeting-height
  {
    height: auto !important;
  }
  .mobile-block
  {
    display: block !important;
  }
  .mobile-bottom
  {
    margin-bottom: 5px;
    
  }
  .modal-fullscreen-xl-purchase .modal-dialog
  {
    width: 98% !important;
  }
  .mobile-main-space-pad
  {
      padding: 15px !important;
  }
  .mobile-enable
  {
    display: block;
  }
  .mobile-pad-right-0
  {
    padding-right: 0 !important;
  }
  .mobile-fixed-top
  {
    position: relative;
  }
  .mobile-shadow
  {
    box-shadow: none;
  }
  .vertical-spacer
  {
    display: none;
  }
  .mobile-google span:nth-of-type(1)
  {
    display: none;
  }
  .mobile-google
  {
    background-color: rgb(221, 75, 57) !important;
    display: inline-block;
    position: relative;
    cursor: pointer;
    width: 3.125rem;
    height: 3.125rem;
    border: 0.125rem solid transparent;
    padding: 0;
    text-decoration: none;
    text-align: center;
    color: #fefefe;
    font-size: 1.5625rem;
    font-weight: normal;
    border-radius: 1.6875rem !important;
    transition: all 0.5s ease;
    margin-right: 0.25rem;
    margin-bottom: 0.25rem;
  }
  .mobile-google div:nth-of-type(1)
  {
    padding: 15px !important;
    background-color: transparent !important;
  }
  .mobile-google div:nth-of-type(1) svg
  {
    display: none;
  }
  .mobile-google div:nth-of-type(1):after
  {
    font-family: FontAwesome;
    content: "\f1a0";
    color: #fff;
    font-size: 25px;
  }
}
@include media-breakpoint-up(md) 
{ 
  .align-text-right  
  {
    text-align: right;
  }
}
@include media-breakpoint-up(lg) 
{ 
  .align-text-right  
  {
    text-align: right;
  }
  // .mobile-bottom
  // {
  //   margin-bottom: 5px;
    
  // }
}
@include media-breakpoint-up(xl) 
{ 
  .align-text-right  
  {
    text-align: right;
  }
}
.input-fields .p-calendar
{
  display: initial;
}
.attachment-inside.card-body p
{
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
}
.attachment-inside div:first-child
{
  background-color: #fff !important;
  border-color: #f3f3f3 !important;
}

.elipsis-dot
{
  position: absolute;
  top: 5px;
  right: 5px;
  color: #dcdcdc;
}
.small-font-dropdown .dropdown-item
{
  font-size: 12px;
  font-weight: 500;
}
.top-header-dropdown-menu .dropdown-menu
{
  width: 250px;
} 
.top-header-dropdown-menu .dropdown-item
{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
} 
.top-header-dropdown-menu  .main-company
{
    max-width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 0px;
    margin-right: 0px;
} 
.top-header-dropdown-menu  i
{
    position: absolute;
    right: -12px;
    top: 35%;
    font-size: 16px;
    transform: translateY(-50%);
}    
.small-font-dropdown
{
border-top: 3px solid #d2d6de !important;
}
.small-font-dropdown::before
{
  display: none;
}
// .number {
//   height: 22px;
//   width: 22px;
//   background-color: #d63031;
//   border-radius: 20px;
//   color: white;
//   text-align: center;
//   position: absolute;
//   top: -13px;
//   left: 21px;
//   padding: 3px;
//   font-size: 8px;
//   border-style: solid;
//   border-width: 2px;
// }
.notifier {
  position: relative;
  display: inline-block;
}
.ring-bell{
  display:block;
  // width: 40px;
  // height: 40px;
  // font-size: 40px;
  // margin:50px auto 0;
  // color: #9e9e9e;
  -webkit-animation: ring 6s .7s ease-in-out infinite;
  -webkit-transform-origin: 50% 4px;
  -moz-animation: ring 6s .7s ease-in-out infinite;
  -moz-transform-origin: 50% 4px;
  animation: ring 6s .7s ease-in-out infinite;
  transform-origin: 50% 4px;
}

@-webkit-keyframes ring {
  0% { -webkit-transform: rotateZ(0); }
  1% { -webkit-transform: rotateZ(30deg); }
  3% { -webkit-transform: rotateZ(-28deg); }
  5% { -webkit-transform: rotateZ(34deg); }
  7% { -webkit-transform: rotateZ(-32deg); }
  9% { -webkit-transform: rotateZ(30deg); }
  11% { -webkit-transform: rotateZ(-28deg); }
  13% { -webkit-transform: rotateZ(26deg); }
  15% { -webkit-transform: rotateZ(-24deg); }
  17% { -webkit-transform: rotateZ(22deg); }
  19% { -webkit-transform: rotateZ(-20deg); }
  21% { -webkit-transform: rotateZ(18deg); }
  23% { -webkit-transform: rotateZ(-16deg); }
  25% { -webkit-transform: rotateZ(14deg); }
  27% { -webkit-transform: rotateZ(-12deg); }
  29% { -webkit-transform: rotateZ(10deg); }
  31% { -webkit-transform: rotateZ(-8deg); }
  33% { -webkit-transform: rotateZ(6deg); }
  35% { -webkit-transform: rotateZ(-4deg); }
  37% { -webkit-transform: rotateZ(2deg); }
  39% { -webkit-transform: rotateZ(-1deg); }
  41% { -webkit-transform: rotateZ(1deg); }

  43% { -webkit-transform: rotateZ(0); }
  100% { -webkit-transform: rotateZ(0); }
}

@-moz-keyframes ring {
  0% { -moz-transform: rotate(0); }
  1% { -moz-transform: rotate(30deg); }
  3% { -moz-transform: rotate(-28deg); }
  5% { -moz-transform: rotate(34deg); }
  7% { -moz-transform: rotate(-32deg); }
  9% { -moz-transform: rotate(30deg); }
  11% { -moz-transform: rotate(-28deg); }
  13% { -moz-transform: rotate(26deg); }
  15% { -moz-transform: rotate(-24deg); }
  17% { -moz-transform: rotate(22deg); }
  19% { -moz-transform: rotate(-20deg); }
  21% { -moz-transform: rotate(18deg); }
  23% { -moz-transform: rotate(-16deg); }
  25% { -moz-transform: rotate(14deg); }
  27% { -moz-transform: rotate(-12deg); }
  29% { -moz-transform: rotate(10deg); }
  31% { -moz-transform: rotate(-8deg); }
  33% { -moz-transform: rotate(6deg); }
  35% { -moz-transform: rotate(-4deg); }
  37% { -moz-transform: rotate(2deg); }
  39% { -moz-transform: rotate(-1deg); }
  41% { -moz-transform: rotate(1deg); }

  43% { -moz-transform: rotate(0); }
  100% { -moz-transform: rotate(0); }
}

@keyframes ring {
  0% { transform: rotate(0); }
  1% { transform: rotate(30deg); }
  3% { transform: rotate(-28deg); }
  5% { transform: rotate(34deg); }
  7% { transform: rotate(-32deg); }
  9% { transform: rotate(30deg); }
  11% { transform: rotate(-28deg); }
  13% { transform: rotate(26deg); }
  15% { transform: rotate(-24deg); }
  17% { transform: rotate(22deg); }
  19% { transform: rotate(-20deg); }
  21% { transform: rotate(18deg); }
  23% { transform: rotate(-16deg); }
  25% { transform: rotate(14deg); }
  27% { transform: rotate(-12deg); }
  29% { transform: rotate(10deg); }
  31% { transform: rotate(-8deg); }
  33% { transform: rotate(6deg); }
  35% { transform: rotate(-4deg); }
  37% { transform: rotate(2deg); }
  39% { transform: rotate(-1deg); }
  41% { transform: rotate(1deg); }

  43% { transform: rotate(0); }
  100% { transform: rotate(0); }
}
.bell {
  // font-size: 26px;
  // color: #FFF;
  transition: 0.3s;
}

.bell:hover {
  color: #EF476F;
}
.c-avatar-img
{
  width: 40px;
  height: 40px;
}
.c-av-width
{
  width: 40px;
  height: 40px;
}
.notifier .badge {
  position: absolute !important;
    top: 5px !important;
    left: 5px !important;
    padding: 0 5px;
    font-size: 11px;
    line-height: 16px;
    height: 17px;
    background: #EF476F;
    color: #FFF;
    border-radius: 11px;
    white-space: nowrap;
}

.notifier.new .badge {
  animation: pulse 4s ease-out;
  animation-iteration-count: infinite;
}

@keyframes pulse {
  40% {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(1.3, 1.3, 1.3);
  }

  55% {
    transform: scale3d(1, 1, 1);
  }
  
  60% {
    transform: scale3d(1.3, 1.3, 1.3);
  }

  65% {
    transform: scale3d(1, 1, 1);
  }
}

input[type="search"]:focus::-webkit-search-cancel-button {
  opacity: 0; 
}
.MuiPagination-ul .MuiPaginationItem-page.Mui-selected {
  background-color: rgba(0, 0, 0, 0.08);
  background: #4b9cd8;
  border-color: #4b9cd8;
  color: #ffffff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
// .material-input-select .MuiFormControl-root
// {
//   width: 100%;
// }
.material-input-select .MuiInput-underline:before
{
  border-bottom: 0 !important;
}
.material-input-select .MuiInputBase-input
{
  font-weight: 500 !important;
  font-size: 12px !important;
  text-align: right;
  color: transparent;
  padding: 3px 0 3px !important;
  padding-right: 0px !important;
}
.material-input-select .MuiSvgIcon-root
{
  display: none !important;
}
.MuiMenuItem-root
{
  font-size: 12px !important;
  font-weight: 500 !important;
  font-family: inherit !important;
}
.material-input-select .MuiInputBase-input:after
{
  font-family: 'Font Awesome 5 Free';
  content: "\f142";
  padding-left: 5px;
  font-size: 12px;
  font-weight: 600;
  color: #999;
}
.material-input-select-filter .MuiInputBase-input:after
{
  font-family: 'Font Awesome 5 Free';
  content: "\f0b0" !important;
  padding-left: 10px;
  font-size: 14px;
  font-weight: 600;
  color: #28a745!important;
}
.material-input-select .MuiSelect-select:focus
{
  background: transparent !important;
  border-bottom: 0 !important;
  outline: 0 !important;
}
.material-input-select .MuiInput-underline:after
{
  border-bottom: 0 !important;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus
{
  box-shadow: none;
}
.dropdown-item:active
{
  background-color: #f3f3f3;
  color: #4f5d73;
}
@media (min-width: 992px) {
  .animate-dropdown {
    animation-duration: 0.3s;
    -webkit-animation-duration: 0.3s;
    animation-fill-mode: both;
    -webkit-animation-fill-mode: both;
    inset: inherit !important;
  }
}

@keyframes slideIn {
  0% {
    transform: translateY(1rem);
    opacity: 0;
  }
  100% {
    transform:translateY(0rem);
    opacity: 1;
  }
  0% {
    transform: translateY(1rem);
    opacity: 0;
  }
}

@-webkit-keyframes slideIn {
  0% {
    -webkit-transform: transform;
    -webkit-opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    -webkit-opacity: 1;
  }
  0% {
    -webkit-transform: translateY(1rem);
    -webkit-opacity: 0;
  }
}

.slideIn {
  -webkit-animation-name: slideIn;
  animation-name: slideIn;
}

.ripple-nav {
  
  border-radius: 100%;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
   width: 40px;
   height: 40px;
   background: #fff;
}
.ripple-nav:hover:before {
  content: "";
  width: 45px;
  height: 45px;
  position: absolute;
  border-radius: 100%;
  -webkit-animation: ripples 0.8s ease-in-out;
          animation: ripples 0.8s ease-in-out;
}

@-webkit-keyframes ripples {
  0% {
    border: 1px solid transparent;
  }
  100% {
    border: 20px solid #bbb;
    opacity: 0;
  }
}
@keyframes ripples {
  0% {
    border: 1px solid transparent;
  }
  100% {
    border: 20px solid #bbb;
    opacity: 0;
  }
}
.dashboard-text
{
  font-size: 26px;
  color: #888;
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;
  // text-shadow: 2px 2px 3px #1212122e;
  // font-family: 'Public Sans', sans-serif;
  // font-family: 'Anton', sans-serif;
}
.dashboard-text-t-amount
{
  font-size: 18px;
  color: #888;
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;
  // text-shadow: 2px 2px 3px #1212122e;
  // font-family: 'Public Sans', sans-serif;
  // font-family: 'Anton', sans-serif;
}
.inr-pre
{
  font-size: 16px;
  margin-top: 19px;
  position: absolute;
  margin-left: -17px;
  display: none;
}
.card-indicator {
  position: absolute;
  height: 70%;
  top: 15%;
  width: 4px;
  border-top-right-radius: .75rem;
  border-bottom-right-radius: .75rem;
  left: 0;
}

.bg-first {
  background-color: #4b9cd8!important;
}
.border-r-b
{
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}
html:not([dir="rtl"]) .input-group > .form-control:not(:first-child), html:not([dir="rtl"]) .input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}
.form-inside-icon
{
   position: absolute;
    color: #ccc;
    top: 10px;
    right: 10px;
    font-size: 14px;
}
.very-small-light
{
  background-color: #f3f3f3;
}
.new-c-app
{
  min-height: 70vh !important;
}
.info-thead-light th
{
  background-color: #f3f3f3 !important;
  border-bottom: 2px solid #4b9cd8 !important;
  color: #4b9cd8 !important;
}

.success-thead-light th
{
  background-color: #f3f3f3 !important;
  border-bottom: 2px solid #2eb85c !important;
  color: #2eb85c !important;
}
/*-- new rect social --*/
/*Pen code from this point on*/
.new-rect-social .btn {
  clear:both;
  white-space:nowrap;
  font-size:14px;
  display:inline-block;
  border-radius:3px;
  margin:2px;
  // -webkit-transition:all .5s;
  // -moz-transition:all .5s;
  // transition:all .5s;
  overflow:hidden;
  width: 100%;
  padding: 0;
  border: 0;
}
.new-rect-social button.btn.btn-googleplus, .single-google-btn button.btn.btn-googleplus
{
  // background-color: rgb(221, 75, 57) !important;
  background-color: #fff;
  font-family: 'Montserrat', sans-serif !important;
  //  box-shadow: none !important;
  -webkit-box-shadow: 0 0.125rem 0.25rem #00001513 !important;
  box-shadow: 0 0.125rem 0.25rem #00001513 !important;
  border-radius: 3px !important;
  opacity: 1 !important;
}
.single-google-btn span
{
  width: 100%;
  text-align: center;
  color: #dd4b39;
}
.new-rect-social button.btn.btn-googleplus div:nth-of-type(1)
{
    padding: 10px !important;
    border-radius: 3px 0 0 3px !important;
    position: relative !important;
    width: 13px !important;
    text-align: center !important;
    font-size: 1.25em !important;
    background: #fff !important;
    width: 45px !important;
    height: 45px !important;
    margin: initial !important;
}
.single-google-btn button.btn.btn-googleplus div:nth-of-type(1)
{
    padding: 10px !important;
    border-radius: 3px 0 0 3px !important;
    // position: relative !important;
    position: absolute;
    left: 18%;
    width: 13px !important;
    text-align: center !important;
    font-size: 1.25em !important;
    // background: rgb(221, 75, 57) !important;
    background: transparent !important;
    width: 45px !important;
    height: 45px !important;
    margin: initial !important;
}
.new-rect-social button.btn.btn-googleplus div:nth-of-type(1):after
{
content: "";
    border: 8px solid;
    border-color: transparent transparent transparent #fff;
    position: absolute;
    top: 13px;
    right: -15px;
}    
.new-rect-social button.btn.btn-googleplus span
{
    font-weight: normal !important;
    padding: 14px 18px 16px !important;
}    
// .new-rect-social .btn:hover {
//   box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.45);
// }

// .new-rect-social .btn:focus {
//   box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.4);
// }

.new-rect-social .btn > span,.new-rect-social .btn-icon > i {
  float:left;
  padding:13px;
  -webkit-transition:all .5s;
  -moz-transition:all .5s;
  transition:all .5s;
  line-height:1em
}

.new-rect-social .btn > span {
  padding:14px 18px 16px;
  white-space:nowrap;
  color:#FFF;
 
}

// .new-rect-social .btn:focus > span {
//   background:#9a9a9a
// }

.new-rect-social .btn-icon > i {
  border-radius:3px 0 0 3px;
  position:relative;
  width:13px;
  text-align:center;
  font-size:1.25em;
 
  background:#fff;
  width: 45px;
  height: 45px;
  margin: initial;
}

.new-rect-social .btn-icon > i:after {
  content:"";
  border:8px solid;
  border-color:transparent transparent transparent #fff;
  position:absolute;
  top:13px;
  right:-15px
}

.new-rect-social .btn-icon:hover > i,.new-rect-social .btn-icon:focus > i {
  color:#FFF
}

.new-rect-social .btn-icon > span {
  border-radius:0 5px 5px 0
}

/*Facebook*/
.new-rect-social .btn-facebook:hover > i,.new-rect-social .btn-facebook:focus > i {
  color:#3b5998
}

.single-entry-input .p-dropdown, .calendar-input .p-inputtext
{
    width: 100%;
    height: calc(1.5em + 0.75rem + 1px);
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    background-clip: padding-box;
    border: 1px solid;
    color: #768192;
    background-color: #fff;
    border-color: #ddd;
    border-radius: 2px;
}
.calendar-input .p-inputtext
{
  font-size: 13px;
}
.single-entry-input .p-dropdown .p-inputtext
{
  color: #768192;
  font-size: 0.875rem;
  line-height: 0.1;
}
.p-dropdown:not(.p-disabled):hover, .p-inputtext:enabled:hover
{
  border-color: #d8dbe0;
}
.d-grid
{
  display: grid;
}
.no-padding-hr .light-card-header
{
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.no-padding-hr hr
{
  display: none;
}
.no-padding-hr .card-body
{
  padding-top: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.vertical-align-middle
{
  vertical-align: middle !important;
}
.table-small-pad-th
{
  padding: 5px !important;
}
.p-10-td td
{
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
.left-th-border-0 thead th:first-child, .left-th-border-0 tbody td:first-child
{
  border-left: 0 !important;
}
.left-th-border-0 thead th:last-child, .left-th-border-0 tbody td:last-child
{
  border-right: 0 !important;
}
.double-table-width th, .double-table-width td
{
  border-width: 1px !important;
}
.double-table-width tbody .white-right-border
{
  border-right: 1px solid transparent !important;
}
.double-table-width .lighted-thead th
{
  background: #fbfbfb;
}
.icon-dashboard-rounded
{
  width: 60px;
  height: 60px;
  line-height: 55px;
  text-align: center;
  min-width: 60px;
  max-width: 60px;
  font-size: 25px;
  border-radius: 50%;
  margin-right: 15px;
}
.success-border
{
  border: 3px solid #2eb85c !important;
  color: #2eb85c !important;
}
.info-border
{
  border: 3px solid #4b9cd8 !important;
  color: #4b9cd8 !important;
}
.danger-border
{
  border: 3px solid #e55353 !important;
  color: #e55353 !important;
}
.border-primary
{
  border: 3px solid #4b9cd8 !important;
  color: #4b9cd8 !important;
}
.border-primary-double
{
  border: 2px solid #4b9cd8 !important;
  color: #4b9cd8 !important;
}
.border-warning
{
  border: 3px solid #f9b115 !important;
  color: #f9b115 !important;
}
.border-dark
{
  border: 3px solid #636f83 !important;
  color: #636f83 !important;
}
.border-dark-double
{
  border: 2px solid #636f83 !important;
  color: #636f83 !important;
}
.dashboard-light-text
{
  color: #888;
}
.bg-tally
{
  background: #151f6d !important
}
.MuiInputBase-root
{
  font-family: inherit !important;
}
.bg-tally-black
{
  background: #444 !important;
}
.facebook-border
{
  border: 2px solid #3b5998 !important;
  color:#3b5998
}
.new-rect-social .btn-facebook {
  background:#3b5998;
  color: #fff;
  text-align: left;
  line-height: 3;
  position: relative;
}
.new-rect-social .btn-facebook i
{
  color: #3b5998;
}

/*Twitter*/
.new-rect-social .btn-twitter:hover > i,.new-rect-social .btn-twitter:focus > i {
  color:#55acee
}
.twitter-border
{
  border: 2px solid #55acee !important;
  color:#55acee
}
.new-rect-social .btn-twitter {
  background:#55acee
}

/*Google*/
.new-rect-social .btn-googleplus:hover > i,.new-rect-social .btn-googleplus:focus > i {
  color:#dd4b39
}
.google-border
{
  // border: 2px solid #dd4b39 !important;
  color:#dd4b39
}
.new-rect-social .btn-googleplus {
  background:#dd4b39
}


/*LinkedIn*/
.new-rect-social .btn-linkedin:hover > i,.btn-linkedin:focus > i {
  color:#007bb6
}
.linkedin-border
{
  border: 2px solid #007bb6 !important;
  color:#007bb6
}
.new-rect-social .btn-linkedin {
  background:#007bb6
}
.large-extra-font
{
  font-size: 2.5rem;
}
// .or-position {
//   position: absolute;
//   left: 50%;
//   height: 45%;
//   border-left: 1px solid #dadada;
// }
// .or-text
// {
//   background: #f3f3f3;
//   padding: 10px;
//   border-radius: 50%;
//   font-weight: 600;
//   position: absolute;
//   left: -20px;
//   top: 40%;
// }
.vertical-spacer {
  left: 34%;
  width: 275px;
  // position: absolute;
  // transform: rotate(90deg);
  transform: rotate(180deg)
}
.vertical-spacer .or-layer-mask {
  overflow: hidden;
  height: 20px;
}
.vertical-spacer .or-layer-mask:after {
  content: '';
  display: block;
  margin: -25px auto 0;
  width: 100%;
  height: 25px;
  border-radius: 125px / 12px;
  box-shadow: 0 0 5px #ccc;
}
.vertical-spacer span {
  width: 40px;
  height: 40px;
  position: absolute;
  bottom: 100%;
  margin-bottom: -21px;
  left: 50%;
  // transform: rotate(270deg);
  transform: rotate(180deg);
  margin-left: -25px;
  border-radius: 100%;
  box-shadow: 0 2px 4px #999;
  background: #fff;
}
.vertical-spacer span i {
  position: absolute;
  top: 4px;
  bottom: 4px;
  left: 4px;
  right: 4px;
  border-radius: 100%;
 font-weight: 600;
  text-align: center;
  line-height: 32px;
  font-style: normal;
  color: #fff;
}
.light-login-bg
{
  background: #f9f9f9;
}
.p-datepicker .p-datepicker-header
{
  font-weight: 600;
}
/*-- new rect social ends --*/
.search-text-box
{
  height: 40px;
  display: inline-block;
  width: 50%;
}
.p-dropdown .p-dropdown-panel
{
  width: 100%;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item
{
  font-weight: 500;
  font-size: 12px;
  color: #888;
  white-space: inherit;
}
.p-dropdown.p-component.p-inputwrapper .p-dropdown-trigger-icon.pi.pi-chevron-down.p-clickable
{
  font-size: 10px;
  font-weight: 600;
}
.right-side .modal.right .modal-dialog {
	position: fixed;
	margin: auto;
	width: 500px;
	height: 100%;
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
  -webkit-font-smoothing: antialiased;
}
.rightside-medium-modal .modal.right .modal-dialog {
	position: fixed;
	margin: auto;
	width: 500px;
	height: 100%;
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
  -webkit-font-smoothing: antialiased;
}
.notify-modal .modal.right .modal-dialog
{
  width: 350px;
}
.notify-modal .modal.right .modal-content
{
  height: 95% !important;
  margin-right: 10px;
  border-radius: 10px !important;
  overflow-x: hidden;
}
.rightside-medium-modal .modal.right .modal-content {
  height: 100%;
  overflow-y: auto;
}
.right-side .modal.right .modal-content {
  height: 100%;
  overflow-y: auto;
}
.rightside-medium-modal .modal.right .modal-content {
  height: 100%;
  overflow-y: auto;
}
.modal-body-notify-scroll
{
  overflow-x: hidden;
  overflow-y: auto;
}
.right-side .modal.right .modal-content
{
  border: 0;
  border-radius: 0;
}
.rightside-medium-modal .modal.right .modal-content
{
  border: 0;
  border-radius: 0;
}
.nested-modal
{
  background: #00000091!important;
}

.right-side .modal.right .modal-content {
	height: 100%;
	overflow-y: auto;
}

.right-side .modal.right .modal-body {
	padding: 15px 15px 80px;
}

.right-side .modal.fade.right .modal-dialog {
	right: -500px;
	-webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
	-moz-transition: opacity 0.3s linear, left 0.3s ease-out;
	-o-transition: opacity 0.3s linear, left 0.3s ease-out;
	transition: opacity 0.3s linear, left 0.3s ease-out;
}

.right-side .modal.fade.right.show .modal-dialog {
	right: 0;
}



.rightside-medium-modal .modal.right .modal-content {
	height: 100%;
	overflow-y: auto;
}

.rightside-medium-modal .modal.right .modal-body {
	padding: 15px 15px 80px;
}

.rightside-medium-modal .modal.fade.right .modal-dialog {
	right: -500px;
	-webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
	-moz-transition: opacity 0.3s linear, left 0.3s ease-out;
	-o-transition: opacity 0.3s linear, left 0.3s ease-out;
	transition: opacity 0.3s linear, left 0.3s ease-out;
}

.rightside-medium-modal .modal.fade.right.show .modal-dialog {
	right: 0;
}
.profile-modal .modal.right .modal-dialog
{
  width: 325px;
}
.profile-modal .modal.right .modal-content {
  height: auto;
  overflow-y: auto;
  top: -20%;
  right: 2%;
  border-radius: 3px;
}
.rounded-user-icon
{
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.user-notify-left-div
{
  float: left;
  width: 45px;
  height: 100%;
  display: flex;
  clear: both;
}
.user-notify-right-div
{
  display: table;
}
.light-bill-td-border td
{
  border-bottom: 0 !important;
  border-left: 1px dotted #d8dbe0 !important;
  border-right: 0 !important;
}
.light-bill-td-border tr:last-child td
{
  border-bottom: 1px solid #d8dbe0 !important;
}
.light-bill-td-border-thin tbody tr:last-child td
{
  border-bottom: 1px solid #d8dbe0 !important;
}
.light-bill-td-border td:first-child , .light-bill-td-border th:first-child
{
  border-left: 0 !important;
 
}
.light-bill-td-border td:last-child, .light-bill-td-border th:last-child
{
  border-right: 0 !important;
}
.light-bill-td-border .p-paginator {
  border: 1px solid #ffffff !important;
  background: #fff;
}
tr.p-datatable-emptymessage:hover
{
  background: #ffffff !important;
}
.field-error.error
{
  font-size: 9px;
  font-weight: 500;
}
.no-border-total th
{
  border: 0 !important;
}
.total-bg
{
  background: #f3f3f3;
  font-weight: 600;
  font-size: 16px;
}
.total-amount-bg
{
  background: #f3f3f3;
   box-shadow: 1px 1px 1px 1px #55aae0;
  font-weight: 600;
  font-size: 16px;
}
.total-amount-font-size
{
  font-size: 15px;
}

.text-underline
{
  text-decoration: underline;
}
.no-total-border .un-border .dashed-left-bottom
{
  border-left: 0 !important;
  border-bottom: 1px dashed #f3f3f3 !important;
}
// .no-total-border .un-border td:last-child.dashed-left-bottom
// {
  
//   border-bottom: 2px solid #4b9cd8 !important;
// }
.original-font
{
  color: #777;
}
@media (hover: hover), not all
{
.btn-primary:hover
{
  background-color: #4b9cd8!important;
  border-color: #4b9cd8!important;
}
}
.bg-or
{
  background-color: #4b9cd8!important;
}
.bold-label .MuiTypography-body1
{
  font-weight: 600;
  font-size: 12px;
  color: #777;
  font-family: inherit;
  
}
.act-size
{
  font-size: 13px;
}
.light-border-color
{
  border-color: #f3f3f3;
}
.reset-icon-size
{
  font-size: 50px;
}
.p-datatable-wrapper table td
{
  border: 1px dotted #f3f3f3 !important;
}
.p-datatable-wrapper table td:first-child, .p-datatable-wrapper table thead th:first-child
{
  border-left: 0 !important;
}
.p-datatable-wrapper table td:last-child, .p-datatable-wrapper table thead th:last-child
{
  border-right: 0 !important;
}
.min-font-size-modal
{
  font-size: 10px !important;
}
// .cash-payment-view
// {
//   position: relative;
//   top: 50%;
//   transform: translateY(15%);
// }
.bg-modal-cash
{
  background: #fbfbfb;
}
.bg-modal-cash-header
{
  background-color: #fbfbfb !important;
}
.v-lign-middle
{
  vertical-align: middle !important;
}
.modal-header
{
  border-color: #f5f5f5;
}
.MuiAutocomplete-endAdornment
{
  display: none;
}
.MuiOutlinedInput-root
{
  border-radius: 2px !important;
}
.mui-input .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]
{
  padding-right: 0;
}
.mui-input .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]
{
  padding: 0px;
}
.mui-input .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
  padding-left: 6px;
  padding: 0.545rem 0.75rem !important;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  font-size: 13px !important;
 
}
.MuiAutocomplete-option {
  min-height: auto !important;
  font-size: 12px !important;
  color: #888 !important;
  width: 100% !important;
}
.mui-input .MuiInputBase-root
{
  color: inherit;
}
.light-font-weight
{
  font-size: 11px;
  font-weight: 500 !important;
}
.new-close-button.modal-header button.close
{
  position: absolute;
    right: -10px;
    top: -8px;
    width: 30px;
    height: 30px;
    padding: 0;
    margin: 0;
    background: #fff;
    border-radius: 50%;
    box-shadow: 0 10px 20px 0 #0000000d;
}
.new-close-button.journal-close-button.modal-header button.close
{
  
    right: -22px;
    top: -12px;
    box-shadow: 0 10px 20px 0 #0000000d;
}
.less-modal-bold .text-muted
{
  color: #ccc !important;
}
.max-width-85
{
  max-width: 85%;
}
.max-width-25
{
  max-width: 25%;
}
.verticals-tabs .nav-pills-custom .nav-link {
  color: #777;
  background: #fff;
  position: relative;
}

.verticals-tabs .nav-pills-custom .nav-link.active {
  color: #4b9cd8!important;
  background: #fff;
}


/* Add indicator arrow for the active tab */

  .verticals-tabs .nav-pills-custom .nav-link::before {
      content: '';
      display: block;
      border-top: 8px solid transparent;
      border-left: 10px solid #fff;
      border-bottom: 8px solid transparent;
      position: absolute;
      top: 50%;
      right: -10px;
      transform: translateY(-50%);
      opacity: 0;
  }

  .verticals-tabs .nav-pills-custom .nav-link.active::before {
  opacity: 1;
}
.profile-input-text
{
  height: 45px;
}
.c-header .c-header-toggler-icon
{
  background-image: none !important;
  height: inherit;
}
.c-header.c-header-fixed
{
  z-index: 1030;
}
.c-header .c-header-toggler-icon::before
{
  
  font-family: 'Font Awesome 5 Free';
  content: "\f0c9";
  font-size: 16px;
  font-weight: 600;
  color: #777;
}
.main-company
{
  color: #777;
}
.mobile-enable
{
  display: none;
}
/*-- toast --*/
.toast-mess .p-toast .p-toast-message.p-toast-message-error
{
  background: #555!important;
}
.toast-mess .p-toast .p-toast-message.p-toast-message-success
{
  background: #2eb85c!important;
}
.toast-mess .p-toast .p-toast-message.p-toast-message-warn
{
  background: #ffc107!important;
}
.toast-mess .p-toast .p-toast-message.p-toast-message-info
{
  background: #4b9cd8!important
}

.toast-mess .p-toast
{
  width: 17rem;
  opacity: 1;
}
.toast-mess .p-toast .p-toast-message .p-toast-message-icon.pi.pi-times
{
    font-size: 14px;
    background: #fff;
    color: #dc3545;
    font-weight: 600;
    width: 40px;
    height: 40px;
    border: 3px solid #fff;
    padding: 10px;
    border-radius: 50px;
    margin-top: 1px;
}
.toast-mess .p-toast .p-toast-message .p-toast-message-icon.pi.pi-check
{
    font-size: 14px;
    background: #fff;
    color: #2eb85c;
    font-weight: 600;
    width: 40px;
    height: 40px;
    border: 3px solid #fff;
    padding: 10px;
    border-radius: 50px;
    margin-top: 1px;
}
.toast-mess .p-toast .p-toast-message .p-toast-message-icon.pi.pi-exclamation-triangle
{
    font-size: 14px;
    background: #fff;
    color: #ffc107;
    font-weight: 600;
    width: 40px;
    height: 40px;
    border: 3px solid #fff;
    padding: 10px;
    border-radius: 50px;
    margin-top: 1px;
}
.toast-mess .p-toast .p-toast-message .p-toast-message-icon.pi.pi-info-circle
{
    font-size: 14px;
    background: #fff;
    color: #4b9cd8;
    font-weight: 600;
    width: 40px;
    height: 40px;
    border: 3px solid #fff;
    padding: 10px;
    border-radius: 50px;
    margin-top: 1px;
}
.toast-mess .p-toast .p-toast-message .p-toast-message-content
{
  padding: 0.5em;
  color: #fff;
}
.toast-mess .p-toast .p-toast-message .p-toast-message-content .p-toast-summary
{
  font-size: 14px;
  font-weight: 600;
}
.toast-mess .p-toast .p-toast-message .p-toast-message-content .p-toast-detail {
  margin: 0.5rem 0 0 0;
  margin-top: 0px;
  font-size: 12px;
  font-weight: 500;
}
.toast-mess .p-toast .p-toast-message .p-toast-icon-close
{
  width: 2em;
  height: 1em;
  margin-top: 15px;
  font-size: 12px;
  color: #fff;
}
.bg-very-light-header
{
  background-color: #f9f9f9 !important;
}
.very-light-btn
{
  background-color: #f9f9f9;
  border: 1px solid #f9f9f9;
}
.very-light-btn:hover
{
  background-color: #f3f3f3;
  border: 1px solid #f3f3f3;
  color: #444 !important;
}
.btn-dark:not(:disabled):not(.disabled):active, .show > .btn-dark.dropdown-toggle
{
  background-color: #f3f3f3;
  border: 1px solid #f3f3f3;
  color: #444 !important;
}
.profile-large-image img
{
  border: 5px solid #ffffff;
}
.profile-large-image
{
    position: absolute;
    top: -30px;
    // width: 100%;
    overflow-x: hidden;
    left: 40%;
}
.profile-right-icon
{
    text-align: right;
    right: 60px;
    padding-top: 5px;
    position: absolute;
}
.journal-td-border td
{
  border-top: 1px dashed #f3f3f3 !important;
}
.journal-td-border tr.un-border:last-child td
{
  border-bottom: 0 !important;
}
.two-factor-verification
{
  color: #666;
}
.verify-font-text
{
  font-size: 15px;
}
.verify-bg
{
  background: #ebedef;
}
.verify-icon-view
{
  font-size: 3em;
  border-radius: 50%;
  border: solid #4b9cd8;
  color: #4b9cd8;
  line-height: 2em;
  width: 2em;
  height: 2em;
  text-align: center;
  display: inline-block;
  transition: 0.5s;
}
.MuiInputBase-input
{
  font-size: 0.8125rem !important;
}
.btn-outline-info
{
  border-color: #4b9cd8;
}
.btn-outline-info:not(:disabled):not(.disabled):active{
  color: inherit !important;
  background-color: transparent !important;
  border-color: #4b9cd8  !important;
  box-shadow: none !important
}
.btn-outline-primary
{
  border-color: #4b9cd8;
}
.btn-outline-primary:hover
{
  background-color: #4b9cd8 !important;
  color: #fff !important;

}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active
{
  background-color: #4b9cd8 !important;
  border-color: #4b9cd8 !important;
  color: #fff !important;
}
.btn-outline-info:hover
{
  background: inherit;
}
.verify-success-icon-view
{
  font-size: 3em;
  border-radius: 50%;
  border: solid #28a745!important;
  color: #28a745!important;
  line-height: 2em;
  width: 2em;
  height: 2em;
  text-align: center;
  display: inline-block;
  transition: 0.5s;
}
.info-icons-icon-view
{
  font-size: 3em;
  border-radius: 50%;
  border: solid #4b9cd8 !important;
  color: #4b9cd8 !important;
  line-height: 2em;
  width: 2em;
  height: 2em;
  text-align: center;
  display: inline-block;
  transition: 0.5s;
}
.verify-warning-icon-view
{
  font-size: 3em;
  border-radius: 50%;
  border: solid #ffc107!important;
  color: #ffc107!important;
  line-height: 2em;
  width: 2em;
  height: 2em;
  text-align: center;
  display: inline-block;
  transition: 0.5s;
}
.line-height-custom
{
  line-height: 1.4;
}
.left-pad
{
  padding-left: 31px;
}
.shape
{
  background: #f9f9f9;
    height: 100%;
    position: relative;
}
.shape::before {
  background: #ffffff none repeat scroll 0 0;
    content: "";
    height: 100%;
    position: absolute;
    right: -100px;
    transform: skew(-7deg);
    width: 150px;
}
/*-- toast ends here --*/
/*-- steps --*/
#heading {
  text-transform: uppercase;
  color: #4b9cd8 ;
  font-weight: normal
}

#msform {
  text-align: center;
  position: relative;
  margin-top: 20px
}

#msform fieldset {
  background: white;
  border: 0 none;
  border-radius: 0.5rem;
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  padding-bottom: 20px;
  position: relative
}

.form-card {
  text-align: left
}

#msform fieldset:not(:first-of-type) {
  display: none
}


.fs-title {
  font-size: 25px;
  color: #4b9cd8 ;
  margin-bottom: 15px;
  font-weight: normal;
  text-align: left
}

.purple-text {
  color: #4b9cd8 ;
  font-weight: normal
}

.steps {
  font-size: 25px;
  color: gray;
  margin-bottom: 10px;
  font-weight: normal;
  text-align: right
}

.fieldlabels {
  color: gray;
  text-align: left
}

#progressbar-steps {
  margin-bottom: 30px;
  overflow: hidden;
  color: #777;
}

#progressbar-steps .active {
  color: #4b9cd8 
}

#progressbar-steps li {
  list-style-type: none;
  font-size: 15px;
  width: 25%;
  float: left;
  position: relative;
  font-weight: 400
}

#progressbar-steps #account:before {
  font-family: FontAwesome;
  content: "\f0a6"
}

#progressbar-steps #personal:before {
  font-family: FontAwesome;
  content: "\f013"
}

#progressbar-steps #payment:before {
  font-family: FontAwesome;
  content: "\f046"
}

#progressbar-steps #confirm:before {
  font-family: FontAwesome;
  content: "\f00c"
}

#progressbar-steps li:before {
  width: 50px;
  height: 50px;
  line-height: 45px;
  display: block;
  font-size: 20px;
  color: #ffffff;
  background: #f9f9f9;
  border-radius: 50%;
  margin: 0 auto 10px auto;
  padding: 2px
}

#progressbar-steps li:after {
  content: '';
  width: 100%;
  height: 2px;
  background: f9f9f9;
  position: absolute;
  left: 0;
  top: 25px;
  z-index: -1
}

#progressbar-steps li.active:before,
#progressbar-steps li.active:after {
  background: #4b9cd8 
}

#progressbar-steps .progress {
  height: 20px
}

#progressbar-steps .progress-bar {
  background-color: #4b9cd8 
}
.tree-semi-border-td-th .inside-table-data  .p-datatable.inside-table-th-td thead th
{
  border: 1px solid transparent !important;
  background: #f3f3f3 !important;

}

.tree-semi-border-td-th .p-datatable-thead tr th
{
  border: 0 !important;
  background: #fff;
  // font-size: 10px !important;
}
.tree-semi-border-td-th.inside-payables .p-datatable-thead tr th
{
  font-size: 10px !important;
  text-transform: inherit;
}
.tree-semi-border-td-th .p-datatable-thead
{
  border-bottom: 2px solid #e8e8e8 !important;
}
.tree-semi-border-td-th .p-datatable-tbody tr td
{
  border-right: 0 !important;
  border-left: 0 !important;
  border-bottom: 1px dotted #f3f3f3 !important;
}
.tree-semi-border-td-th .inside-table-data th
{
  border: 0 !important;
}

.tree-semi-border-td-th.inside-payables span.pi-chevron-down:before
{
  font-family: 'Font Awesome 5 Free';
  content: "\f141";
  padding-right: 5px;
  font-size: 16px;
  font-weight: 600;
  color: #4b9cd8;
}
.tree-semi-border-td-th.inside-payables span.pi-chevron-right:before
{
  font-family: 'Font Awesome 5 Free';
  content: "\f141";
  padding-right: 5px;
  font-size: 16px;
  font-weight: 600;
  color: #aaa;
}
#main #faq .card .card-header .btn-header-link:after {
  content: "\f107";
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  float: right;
}

#main #faq .card .card-header .btn-header-link.collapsed {
 
  color: #777;
}

#main #faq .card .card-header .btn-header-link.collapsed:after {
  content: "\f106";
}

#main #faq .card .collapse {
  border: 0;
}
#main #faq .card .card-header .btn-header-link {
  color: #777;
  display: block;
  text-align: left;
  font-weight: 600;
  padding: 5px;
}
/*-- line tabs --*/
.p-tabview.tab-view-line .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background: transparent;
  color: #4B9CD8 !important;
  border-color: #4B9CD8;
  border-bottom: 2px solid #4B9CD8!important;
  border-radius: 0;
}
.p-tabview.tab-view-line .p-tabview-nav li .p-tabview-nav-link {
  background: transparent;
  font-size: 12px;
  padding: 0 !important;
  box-shadow: none !important;
  margin-right: 20px !important;
  
}
.p-tabview.tab-view-line .p-tabview-nav li .p-tabview-nav-link .p-tabview-title
{
  line-height: 2rem;
}

.p-tabview.tab-view-line .p-tabview-nav li .p-tabview-nav-link
{
  background: transparent;
  font-size: 12px;
}
.tab-view-line.tabbed-nav-container-default .p-tabview-nav:after
{
  height: 1px !important;
}
.tab-view-line.tabs-before-line.tabbed-nav-container-default .p-tabview-nav:after
{
  height: 0px !important;
}
.p-tabview.tab-view-line .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link
{
  background: transparent;
}
/*-- line tabs ends here --*/
/*-- multi select --*/
.multi-select-inp .p-multiselect
{
  font-size: 0.8125rem !important;
  border: 1px solid #e8ecec !important;
  padding: 0.259rem 0.429rem;
  font-weight: 500;
  width: 100%;
}
.multi-select-inp .p-multiselect .p-multiselect-label  
{
  padding: inherit;
}
.multi-select-inp ul li span
{
  font-size: 12px;
  font-weight: 500;
}
.n-h-w-m
{
  width: inherit !important;
  height: inherit !important;
  margin: 0 !important;
}
.multi-select-inp .p-multiselect-trigger-icon.pi.pi-chevron-down.p-c
{
  font-size: 0.8125rem !important;
}
.tags {
  list-style: none;
  margin: 0;
  overflow: hidden; 
  padding: 0;
}

.tags li {
  float: left; 
}

.no-card-bg-tabs .p-tabview-panels
{
  background: transparent;
}
 .collapse-btn-header-link h6:after {
  content: "\f13a";
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  float: right;
}
.collapse-btn-header-link.collapsed h6:after {
  content: "\f139";
}
.p-datatable .p-datatable-tfoot > tr > td
{
    font-weight: 600;
    color: #666;
    background: #fff;
    padding-left: 6px;
}

.p-datatable .p-datatable-tfoot > tr > td:first-child
{
  background: #f9f9f9 !important;
}
// .p-datatable .p-datatable-tfoot
// {
//   border-top: 2px solid #f3f3f3 !important;
// }
.closing-opening-bal .border-bottom-empty-line ,.closing-opening-bal tfoot .border-bottom-empty-line 
{
  border-bottom: 1px solid transparent !important;
}

.closing-opening-bal .border-top-empty-line, .closing-opening-bal tfoot .border-top-empty-line 
{
  border-top: 1px solid transparent !important;
}
.c-header
{
  border-bottom: 0 !important;
}
/*-- modal full screen --*/

.modal-fullscreen-xl-purchase {
  padding: 0 !important;
}
.modal-fullscreen-xl-purchase .modal-dialog {
  width: 100%;
  max-width: none;
  height: 100%;
  margin: 0;
  width: 50%;
}
.modal-fullscreen-xl-purchase .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen-xl-purchase.medium-modal-screen .modal-dialog {
 
  width: 35%;
 
}
.modal-fullscreen-xl-purchase .modal-body {
  overflow-y: auto;
  overflow-x: hidden;
}
.box-top-shadow
{
    box-shadow: 0 -6px 10px -10px #d0d0d0;
    z-index: 11;
}
.box-top-shadow
{
    box-shadow: 0 -6px 10px -10px #d0d0d0;
    z-index: 11;
}
.center-nav-tabs .nav-tabs
{
    display: flex;
    justify-content: center;
    flex-direction: row;
    border-bottom: 0;
}
.center-nav-tabs .nav-tabs .nav-link
{
    background: #fff;
    border-radius: 25px;
    margin-right: 10px;
    font-size: 12px;
    font-weight: 600;
}    
.border-top-bottom-radius-0
{
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.border-top-bottom-radius-25
{
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}
.border-top-bottom-right-radius-25
{
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}
.sales-progress .p-progressbar .p-progressbar-value
{
  background: #4b9cd8;
}
.sales-progress .p-progressbar-determinate .p-progressbar-label
{
  font-size: 12px;
  font-weight: 600;
  color: #777;
}
.sales-progress .p-progressbar
{
  background: #f9f9f9;
}
.recievable-icon
{
    position: absolute;
    top: 0px;
    font-size: 25px;
    margin-left: 10px;
}
.autocomplete-input
{
  padding: 10px 12px 12px 35px;
  background-size: 3%;
}
.badge
{
  font-weight: 500;
}
.common-search-disable .MuiTablePagination-actions
{
  display: none;
}
.common-search-disable .MuiTablePagination-toolbar
{
  min-height: 25px;
}
.common-search-disable .MuiTablePagination-toolbar p:nth-of-type(2)
{
  display: none;
}
.main-google div:nth-of-type(1) svg
  {
    display: none;
  }
  .main-google div:nth-of-type(1):before
  {
    font-family: FontAwesome;
    content: "\f1a0";
    // color: #dd4b39 !important;
    color: #dd4b39;
    font-size: 1.25em;
    line-height: 1;
  } 
  .btn-facebook:before
  {
    font-family: FontAwesome;
    content: "\f09a";
    color: #3b5998 !important;
    font-size: 1.25em;
    line-height: 1;
    padding: 18px !important;
    border-radius: 3px 0 0 3px !important;
    position: relative !important;
    width: 13px !important;
    text-align: center !important;
    font-size: 1.25em !important;
    background: #fff !important;
    width: 45px !important;
    height: 45px !important;
    margin: 0 !important;
    margin: initial !important;
  }   

  .btn-facebook:after 
  {
    content: "";
    border: 8px solid;
    border-color: transparent transparent transparent #fff;
    position: absolute;
    top: 13px;
    left: 45px;
  }
  .card-columns
  {
    -webkit-column-count: 2;
    column-count: 2;
    -webkit-column-gap: 1.25rem;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
}
  .card-columns .card{
    display: inline-block;
    width: 100%;
    // box-shadow: none;
    position: inherit;
    margin-bottom: 0;
    padding: 0;
}
.high-light-border td
{
  border-left: 0 !important;
}
.light-td-border-thin.dashboard-table-last-td tbody tr:last-child td {
  border-bottom: 0px solid #d8dbe0 !important;
}
.very-small-light-border
{
  border: 1px solid #f3f3f3;
  border-radius: 3px;
  cursor: pointer;
}
.very-small-light-border:hover
{
  background: #f3f3f3;
  cursor: pointer;
}
.negative-margin
{
  margin-top: -10px;
}
.year-dropdown .dropdown-toggle::after
{
  display: none;
}

.has-search .form-control {
  padding-left: 2.375rem;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 45px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  pointer-events: none;
  color: #666;
}
.chip-search-results {
  display: inline-block;
  // padding: 0 25px;
  // height: 50px;
  // font-size: 16px;
  // line-height: 50px;
  border-radius: 25px;
  background-color: #fff;
  margin: 5px;
}
.chip-search-results span.inner-text-name
{
  line-height: 30px;
}
.chip-search-results i {
  float: left;
    border-radius: 50%;
    width: 30px;
    line-height: 30px;
    height: 30px;
    color: #151f6d;
    background: #fff;
}
.btn-glyphicon-un {
  padding:8px;
  background:#ffffff;
  margin-right:4px;
  width: 30px;
  height: 30px;
}
.icon-btn-rounded {
    padding: 3px 15px 3px 3px;
    border-radius: 50px;
    margin: 10px;
}
.monthly-profit-chat-canvas canvas
{
  width: 100% !important;
}
.top-main-nav-search
{
    padding-left: 30px;
    border-radius: 3px;
    background: #eef3f8;
    border-color: #eef3f8;
}
.top-main-nav-search:focus{
  background: #eef3f8;
  border-color: #eef3f8;
}
.document-search-dt .p-datatable .p-datatable-thead > tr > th {
  padding: 6px !important;
  color: #666;
  font-size: 14px !important;
  text-transform: capitalize;
}
.document-search-dt .p-datatable .p-datatable-tbody tr td:first-child
{
  color: #4b9cd8 !important;
}
.document-search-dt .p-datatable .p-datatable-tbody tr td:first-child::before
{
  
    font-family: FontAwesome;
    content: "\f15c";
    color: #4b9cd8;
    font-size: 14px;
    margin-right: 5px;
  
}
.document-search-dt .p-datatable .p-datatable-tbody tr td
{
  font-size: 14px;
  font-weight: 500;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {display:none;}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input.default:checked + .slider {
  background-color: #444;
}
input.primary:checked + .slider {
  background-color: #2196F3;
}
input.success:checked + .slider {
  background-color: #8bc34a;
}
input.info:checked + .slider {
  background-color: #3de0f5;
}
input.warning:checked + .slider {
  background-color: #FFC107;
}
input.danger:checked + .slider {
  background-color: #f44336;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.expenses-top-bottom-th
{
  padding-top: 14px  !important;
  padding-bottom: 13px !important;
}
.row-colspan-datatable thead th
{
  background-color: #fbfbfb !important;
}
.row-colspan-datatable .p-datatable-wrapper  thead.p-datatable-thead th.enable-left-border
{
  border-left: 1px solid #f4f4f4 !important;
}
.table-fixed-layout-elipsis
{
  width: 100%;
  table-layout: fixed;
}
.border-bottom-light-c
{
  border-bottom: 1px solid #f3f3f3;
}
// .login-ill
// {
//   background-image: url(../assets/icons/l-l-h.svg), url(../assets/icons/l-r-h.svg);
// 	background-repeat: no-repeat, no-repeat;
// 	background-attachment: fixed, fixed;
// 	background-size: 250px, 250px;
// 	background-position: left bottom, right bottom;
// }
.c-sidebar.c-sidebar-fixed
{
  z-index: 1030 !important;
}

.profile-large-image img
{
  width: 65px;
  height: 65px;
  border-radius: 50% !important;
}
.balance-sheet-highlight .p-treetable .p-treetable-tbody > tr.p-highlight
{
  background: transparent;
}
.balance-sheet-highlight .p-treetable .p-treetable-tbody > tr
{
  outline-color: transparent;
}
.p-treetable .p-treetable-tbody > tr.p-highlight .p-treetable-toggler
{
  color: #4b9cd8;
  font-weight: 600;
}

.balance-sheet-highlight .p-highlight span, .balance-sheet-highlight .p-highlight td
{
  color: #4b9cd8 !important;
  font-weight: 600 !important;
}
.org-comp-info-diaalog .p-dialog-mask
{
  z-index: 9999 !important;
}
.verify-item-c-app .c-app
{
  min-height: inherit;
}
.inside-pinp-text .p-inputtext
{
  border: 0 !important;
  padding: 0 !important;
}
.notifier .new-badge-up
{
  top: -10px !important;
  left: 5px;
}
.rounded-report-btn .btn
{
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 6px 0;
  font-size: 12px;
  line-height: 1.428571429;
  border-radius: 15px;
  -webkit-box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
  box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
}  
.new-title-small-head
{
  font-size: 14px;
  margin-top: 2px;
}
.new-multi-select-modal .p-multiselect .p-multiselect-panel
{
  width: 250px;

}
.text-muted-light
{
  color: #ccc;
}
.tags-labels-li-users .list-group-item.active {
  z-index: 2;
  color: #4b9cd8;
  background-color: #f9f9f9;
  border-color: #fff;
  font-weight: 600;
}
.tags-labels-li-users > .list-group-item {
  border-width: 0 0 1px;
  font-weight: 500;
  font-size: 12px !important;
  border-bottom: transparent;
}
.tags-labels-scroll
{
  height: 600px;
  overflow: auto;
}
.tags-inside-icon
{
  position: absolute;
    right: 20px;
    top: 77px;
    color: #777;
}
.mobile-second-bar
{
  z-index: 1029;
   
    background-color: transparent;
    border-bottom: 1px solid rgb(245, 245, 245);
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
    border-radius: 5px;
    margin-right: 10px;
}
.c-wrapper
{
  background: #f3f3f3;
}
.bg-grey-color
{
  background-color: #f9f9f9 !important;
}
.react-apple-signin-auth-btn
{
  font-weight: 600;
  border-radius: 5px;
}
.signin-btn-login
{
  border-radius: 5px !important; 
  border: 1px solid #e8e8e8 !important;
}
.signin-btn-login div:first-child
{
  border-radius: 5px !important;
}
.text-with-btn-box
{
  height: 27px !important;
  padding: 5px;
}

.file-upload-parent {
  position: relative;
  overflow: hidden;
}
.file-upload-child {
  position: absolute;
  font-size: 50px;
  opacity: 0;
  right: 0;
  top: 0;
}
.double-dotterd-border
{
  border: 2px #f9f9f9 dashed;
}
.company-logo-border
{
  border: dashed #4e9cd8 !important;
  color: #777777 !important;
}
.no-card-header-att .card-header, .no-card-header-att hr
{
  display: none;
}
.no-card-header-att .card-body
{
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
}
.no-card-header-att .card
{
  margin-bottom: 0;
}
.preview-parent
{
  position: relative;
}
.v-h-c
{
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
p.dialog-main
{
  font-size: 16px !important;
  font-weight: 600 !important;
  text-align: center !important;
}
.dialog-actions-padding
{
    padding: 0px !important;
    padding-left: 20px !important;
    padding-bottom: 20px !important;
    padding-right: 20px !important;
}
.center-items.MuiDialogActions-root
{
  text-align: center !important;
  display: block !important;
}
.MuiDialogActions-root
{
  justify-content: center !important;
}
.hover-bg-notify:hover
{
  background-color: #f3f3f3;
}
.left-animation .modal.left .modal-dialog,
.right-animation .modal.right .modal-dialog, .right-side .modal.right .modal-dialog {
  -webkit-transform: translate3d(0%, 0, 0);
      -ms-transform: translate3d(0%, 0, 0);
       -o-transform: translate3d(0%, 0, 0);
          transform: translate3d(0%, 0, 0);
}


/*Left*/
.left-animation .modal.fade.left .modal-dialog{
  left: -320px;
  -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
     -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
       -o-transition: opacity 0.3s linear, left 0.3s ease-out;
          transition: opacity 0.3s linear, left 0.3s ease-out;
}

.left-animation .modal.fade.left.show .modal-dialog{
  left: 0;
}
      
/*Right*/
.right-animation .modal.fade.right .modal-dialog, .right-side .modal.fade.right .modal-dialog {
  right: -320px;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
     -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
       -o-transition: opacity 0.3s linear, right 0.3s ease-out;
          transition: opacity 0.3s linear, right 0.3s ease-out;
}

.right-animation .modal.fade.right.show .modal-dialog, .right-side .modal.fade.right.show .modal-dialog {
  right: 0;
}
.alert-modal-header
{
  background-color: #f9f9f9 !important;
  font-size: 16px;
  font-weight: 500;
}

.alert-modal-header.modal-header button.close
{
  font-weight: 400;
  font-size: 40px;
  font-size: 40px;
  padding: 8px;
}
.alert-modal-header .new-title-small-head
{
  font-size: 16px;
  font-weight: 500;
}

.year-dropdown .dropdown-menu
{
    max-height: 200px;
    overflow-y: auto;
}
.company-dropdown-height
{
    max-height: 250px;
    overflow-y: auto;
}
.p-relative
{
  position: relative;
}
.alert-modal-width  .modal.right .modal-dialog
{
  width: 420px;
}
.h-v-l
{
  height: 0.875rem !important;
  margin: 0.21875rem 0 !important;
  width: 0.875rem !important;
}
.text-inline-1
{
  display: inline-block;
}
.inline-form-control-1
{
  width: 140px;
    display: inline-block;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-radius: 0;
    background: transparent;
    border-bottom: 1px dashed #bbb;
    padding-left: 0;
    padding-right: 0;
    height: 30px;
}
.inline-form-control-2
{
  border-radius: 0;
  display: inline-block;
  width: 150px;
  border-top: 0;
  border-left: 0;
  background: transparent;
  border-bottom: 1px dashed #bbb;
  border-right: 0;
  padding-left: 0;
  padding-right: 0;
  height: 30px;
}
.inline-form-control-max
{
  width: 200px;
}
.text-inline-2
{
display: inline-block;
    width: 140px;
    text-align: right;
}
.text-inline-max
{
display: inline-block;
    width: 50px;
    text-align: left;
}
.exp-table tr td
{
  border-left: 0 !important;
  border-right: 0 !important;
  padding-top: 10px;
  padding-bottom: 15px;
  padding-left: 0;
  padding-right: 0;
}
.exp-table tr:first-child td
{
  border-top: 0 !important;
}
.exp-table tr:last-child td
{
  border-bottom: 0 !important;
}
.b-r-3
{
  border-radius: 3px;
}
.inline-form-control-1-error {
  width: 140px;
  display: inline-block;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-radius: 0;
  background: transparent;
  border-bottom: 1px dashed #e70b0b;
  padding-left: 0;
  padding-right: 0;
  height: 30px;
}
.inline-form-control-2-error
{
  border-radius: 0;
  display: inline-block;
  width: 150px;
  border-top: 0;
  border-left: 0;
  background: transparent;
  border-bottom: 1px dashed #e70b0b;
  border-right: 0;
  padding-left: 0;
  padding-right: 0;
  height: 30px;
}
.dashed-border-bottom {
  border-bottom: 2px dashed #f9f9f9;
  border-radius: 0!important;
}
.dashed-border-bottom-thead {
  border-bottom: 2px dashed #f9f9f9 !important;

}
.table-fixed-alert thead {
  width: 100%;
}
.table-fixed-alert tbody {
  max-height: 380px;
  overflow-y: auto;
  width: 100%;
}
.table-fixed-alert thead, .table-fixed-alert tbody, .table-fixed-alert tr, .table-fixed-alert td, .table-fixed-alert {
  display: block;
}
.table-fixed-alert tbody td, .table-fixed-alert thead > tr> th {
  float: left;
  border-bottom-width: 0;
}
.collapse-td-th tr td:first-child, .collapse-td-th tr th, .collapse-td-th tr th:first-child {
  border-right: 0!important;
}
.collapse-td-th tr td {
  border-top: 0!important;
}
.collapse-td-th tr td {
  border: 0!important;
}
.collapse-td-th tr td, .collapse-td-th tr th {
  border-collapse: collapse;
}
.collapse-td-th tr th {
  border-left: 0!important;
  border-top: 0!important;
}
.alerts-multiselect  .p-multiselect
{
  font-size: 0.8125rem !important;
    border: 1px solid #ddd !important;
    border-radius: 2px;
    height: calc(1.5em + 0.75rem + 10px);
    padding: 0.375rem 0.75rem;
}
.multi-select-inp .alerts-multiselect .p-multiselect .p-multiselect-label
{
  line-height: 2;
}
.lg-form-height, .single-entry-input.lg-form-multiselect-alert .p-dropdown
{
  height: calc(1.5em + 0.75rem + 10px);
}
.b-r-point25
{
  border-radius: 0.25rem !important;
}
.d-contents
{
  display: contents;
}
.single-entry-input.adjust-line-height .p-dropdown .p-inputtext
{
  line-height: 2 !important;
}
.alerts-pdf-dropdown .p-dropdown
{
  font-size: .8125rem!important;
    border: 0px solid #ddd;
    box-shadow: 0 .125rem .25rem rgba(0,0,21,.075)!important;
    border-radius: 2px;
    width: 100%;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1;
    background-clip: padding-box;
    color: #768192;
}
.pdf-alerts-padding-td td
{
  padding: 10px !important;
}
.pdf-check-box
{
  font-size: 1.5em !important;
}
.empty-margin-bottom-0 .mb-5
{
  margin-bottom: 0px !important;
}
.cal-filter .p-inputtext
{
  height: calc(1.5em + 0.75rem + 10px);
  border-radius: 0;
  box-shadow: none;
}
.collapse-td-th tr td, .collapse-td-th tr th
{
  border-collapse: collapse;
}
.collapse-td-th tr td
{
  border: 0 !important;
}
.collapse-td-th tr th
{
  border-left: 0 !important;
  border-top: 0 !important;
  border-right: 0 !important;
}
.collapse-td-th tr td:first-child, .collapse-td-th tr th:first-child
{
  border-right: 0 !important;
}
.collapse-td-th tr td
{
  border-top: 0 !important;
}
.p-calendar .p-inputtext
{
  box-shadow: none;
}
.b-r-10
{
  border-radius: 10px;
}
.p-monthpicker .p-monthpicker-month
{
  font-size: 12px;
  font-weight: 600;
}
.ledger-budgeting 
{
  padding-top: 11px !important;
  padding-bottom: 12px !important;
  background: #fff !important;
  border-right: 0 !important;
  border-left: 0 !important;
  border-bottom: 0 !important;
}
.ledger-budgeting-height
{
  height: 410px;
  overflow: auto;
}
.dashed-ledger-budget td
{
  border-style: dashed !important;
}
.dashed-ledger-budget tr:first-child td
{
  border-top: 0 !important;
}

.c-not-allowed
{
  cursor: not-allowed;
}
.company-list-max-height
{
  max-height: 250px;
  overflow: auto;
}
.overflow-max-logo
{
  max-height: 300px;
  max-width: 300px;
}
.overflow-max-logo-main
{
  max-height: 250px;
  max-width: 200px;
}
.parent-logo-abs-height
{
  width: 100%;
  height: 100%;
  display: table;
}
.child-v-h
{
  display: table-cell;
  vertical-align: middle;
  text-align:center
}
.reports-mat-ui-tabs
{
  background-color: #fff !important;
  -webkit-box-shadow: 0 10px 20px 0 rgba(0,0,0,.05) !important;
  box-shadow: 0 10px 20px 0 rgba(0,0,0,.05) !important;
  padding: 10px;
  border-radius: 3px;
}
.reports-tabs-material .MuiBox-root
{
  padding: 0 !important;
  margin-top: 15px;
}
.MuiTypography-body1
{
  font-family: inherit !important;
  letter-spacing: inherit;
}
.reports-tabs-material .MuiTab-root
{
  font-family: inherit !important;
  letter-spacing: inherit;
  line-height: 32px;
}
.reports-tabs-material .MuiTabs-root
{
  min-height: auto !important;
}
.reports-tabs-material .MuiTab-labelIcon
{
  min-height: auto;
  padding-top: 5px;
  padding-bottom: 5px;
}
.reports-tabs-material .MuiTabs-indicator
{
  background: none;
}
.reports-tabs-material .MuiTab-textColorPrimary.Mui-selected {
  color: #ffffff;
  background: #4b9cd8;
  border-radius: 3px;
 
}
.reports-tabs-material .MuiTab-root
{
  font-size: 12px;
  font-weight: 600;
  text-transform: capitalize;
}

.reports-tabs-material .MuiTab-wrapper
{
  display: block;
}
.dashed-border
{
  border-top: 2px dashed #f9f9f9;
    border-radius: 0  !important;
}
.dashed-border-bottom
{
  border-bottom: 2px dashed #f9f9f9;
  border-radius: 0  !important;
}
.empty-bal-message .p-treetable-tbody .p-treetable-emptymessage
{
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}

.statistic .icon {
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  min-width: 50px;
  font-size: 18px;
  max-width: 50px;
  color: #fff;
  border-radius: 50%;
  margin-right: 15px;
}
.statistic strong {
  font-size: 1.5em;
  color: #777777;
  font-weight: 700;
  line-height: 1;
}
.bg-light-icon
{
  background: #f9f9f9;
}
.overflow-tree-data .p-treetable-tbody td
{
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.disable-event
{
  cursor: not-allowed !important;

}
.inventory-table-view th, .inventory-table-view td
{
  border-color: #f9f9f9 !important;
}
.inventory-table-view th
{
  font-size: 12px;
  font-weight: 600 !important;
}
.inventory-table-view td
{
  font-size: 12px;
  font-weight: 500 !important;
}
.border-top-left-radius-10
{
  border-top-left-radius: 10px;
}
.border-top-right-radius-10
{
  border-top-right-radius: 10px;
}
.border-bottom-left-radius-10
{
  border-bottom-left-radius: 10px;
}
.border-bottom-right-radius-10
{
  border-bottom-right-radius: 10px;
}
.inventory-table-view
{
  border-collapse: separate; 
  border-spacing: 0 0px; 
  
}
.btn-25-radius
{
  border-radius: 25px !important;
}
.light-f9-color td, .light-f9-color th
{
  border-color: #f9f9f9 !important;
}
.table-fixed thead {
  width: 100%;
}
.table-fixed tbody {
  max-height: 180px;
  overflow-y: auto;
  width: 100%;
}
.table-fixed tbody.inventory-view {
  height: 265px;
  max-height: inherit;
  overflow-y: auto;
  width: 100%;
}
.table-fixed thead, .table-fixed tbody, .table-fixed tr, .table-fixed td, .table-fixed th {
  display: block;
}
.table-fixed tbody td, .table-fixed thead > tr> th {
  float: left;
  border-bottom-width: 0;
}
.f9-border-color
{
  border-color: #f9f9f9;
}
.inventory-top-bottom-th
{
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}
.reports-dropdown .p-dropdown
{
  padding: 0 !important;
  box-shadow: none;
}
.reports-dropdown span.p-dropdown-label.p-inputtext
{
  border: 0 !important;
}
.header-dropdown-pop-inside .modal.right .modal-content {
 
  overflow-y: auto;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  // height: auto;
  right: 0%;
  border-radius: 5px;
  -webkit-box-shadow: 0 10px 20px 0 rgba(0,0,0,.05);
  box-shadow: 0 10px 20px 0 rgba(0,0,0,.05);
}
.header-dropdown-pop-inside .modal.right .modal-dialog
{
  
    position: fixed;
    margin: auto;
    margin-top: 60px;
}
.arrow_box .modal-lg {
  
  position: relative;
}
 
.arrow_box .modal-lg:after,
.arrow_box .modal-lg:before {
  bottom: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  top: -9px;
  position: absolute;
}
 
.arrow_box .modal-lg:after {
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #f9f9f9;
  border-width: 5px;
  left: 50%;
  margin-left: 90px;
}
 
.arrow_box .modal-lg:before {
  border-color: rgba(113, 158, 206, 0);
  border-bottom-color: #f9f9f9;
  border-width: 5px;
  left: 50%;
  margin-left: 90px;
}
.dr-padding
{
    line-height: 2.5;
    padding-top: 5px;
    padding-bottom: 5px;
}
.bill-rec-radio label span.MuiRadio-root.MuiRadio-colorPrimary
{
  padding: 0;
  margin-right: 5px;
}
.rec-bill-alert-padd td
{
  border: 0 !important;
  padding: 12px !important;
}
.recivable-td-th th:first-child, .recivable-td-th td:first-child
{
  border-left: 0 !important;
  
}
.recivable-td-th th:last-child, .recivable-td-th td:last-child
{
  border-right: 0 !important;
  
}
.recivable-td-th tr:first-child th
{
  border-top: 0 !important;
  
}
.recivable-td-th tr:last-child td
{
  border-bottom: 0 !important;
  
}
.pdf-check-box-td
{
  font-size: 1.2em !important;
}
.recievable-dr .p-dropdown
{
  height: 45px !important;
  line-height: 2 !important;
}
.recievable-dr .p-dropdown-label.p-inputtext
{
  color: #768192 !important;
}
.excel-padding-global
{
  padding: 0 !important;
 border: 0;
}
.font-size-13
{
  font-size: 13px;
}
.mobile-number-update-dialog .MuiDialogContent-root
{
  padding: 0;
}
.mobile-number-update-dialog .MuiDialogContent-root:first-child
{
  padding-top: 0;
}
.rounded-icon-mobile-symbol i
{
  background: #fff;
  padding: 15px;
  width: 70px;
  height: 70px;
  color: #000;
  font-size: 42px;
  border-radius: 50%;
}
.rounded-icon-mobile-symbol
{
  position: absolute;
  width: 100%;
  top: 23%;
}
.custom-search-btn-inside{
  position: relative;
}
.custom-search-btn-inside .form-control {
  width: 100%;
  padding-right: 100px !important; 
  box-sizing: border-box;
  height: 50px;
}
 
.custom-search-btn-inside .btn {
  position: absolute;
  right: 3px; 
  top: 3px;
  bottom: 3px;
  line-height: 1 !important;
  z-index: 4;
}
.border-radius-25-mobile-update
{
  border-radius: 25px !important;
}

.table-fixed-projection-t-height tbody
{
  max-height: 330px;
}
.budgeting-table .p-datatable .p-datatable-thead > tr > th
{
  background: #fff;
  border: 1px dashed #eee !important;
  border-top: 0 !important;
}
.budgeting-table .p-datatable-scrollable-header-table .p-datatable-thead .budgeting-main-heading
{
  border-width: 0 !important;
  border-color: #fff !important;
}
.budgeting-table  .p-datatable .p-datatable-scrollable-header .p-datatable-scrollable-header-box
{
  background: #fff;
  border-left: 1px dashed #eee;
  border-top: 1px dashed #eee;
  border-bottom: 1px dashed #eee;
  border-right: 0;
}
.budgeting-table .p-datatable .p-datatable-tbody tr td
{
  padding: 10px !important;
  border-left: 0 !important;
  border-right: 0 !important;
  font-size: 12px;
  font-weight: 500;
}
.budgeting-table .p-datatable-scrollable-header-table .p-datatable-thead .top-border-0-budget
{
  border-bottom: 0 !important;
}
.budgeting-table  .p-datatable .p-datatable-tbody tr:first-child td
{
  border-top: 0 !important;
}

.budgeting-table .p-datatable-scrollable-header-table thead tr:first-child th, .budgeting-table .p-datatable-scrollable-header-table thead tr:nth-child(2) th.top-border-0-budget
{
  border-left: 0 !important;
}
.budgeting-table  .p-datatable-scrollable-body-table
{
  margin-bottom: 10px;
}
.switch-bob .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before
{
  background: #fff;
}
.overflow-elipsis-bva
{
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis; 
  width: 250px !important;
  
}
.ledger-view-beside-table th
{
  border-top: 0 !important;
  border-right: 0 !important;
  border-bottom: 2px dashed #f9f9f9 !important;
}
.ledger-budgeting-fixed-tbody tbody
{
  max-height: 335px !important;
  height: 335px !important;
}
.variance-analysis-tbody-h tbody
{
  height: 222px !important;
  max-height: 222px !important;
}
.second-major-head td
{
  border-left: 0 !important;
  border-right: 0 !important;
  border-top: 0 !important;
  border-bottom: 1px dashed #f3f3f3 !important;
}
.form-height-50
{
  height: 50px;
}
.font-size-14
{
  font-size: 14px;
}
.line-height-2
{
  line-height: 2;
}
.otp-inputs-center
{
    display: flex;
    justify-content: center;
}
.otp-input-width
{
  width: 2.5em !important;
}
.otp-input-box
{
    height: 45px;
    background: #f5f4f4;
    margin-left: 5px;
    border-bottom: 2px solid #dededeee !important;
}
.span-minus span
{
  margin-left: 5px;
  margin-right: 5px;
  color: #f9f9f9;
  display: none;
}
.verify-mobile-text-size
{
  font-size: 18px;
}
.line-height-1
{
  line-height: 1;
}
.otp-icon-verify-i i
{
  margin: 0;
  height: inherit;
}
.sub-icon
{
    background: #f9f9f9;
    padding: 10px;
    font-size: 24px;
    border-radius: 50px;
    width: 50px;
    height: 50px;
    border: 2px dotted #fff;
}
.border-1px-solid
{
  border: 1px solid #f9f9f9;
}
.sub-float-right-icon
{
  right: 40px;
  position: absolute;
  font-size: 16px;
}
.border-warning-double-b
{
  border: 2px solid #f9b115 !important;
  color: #f9b115 !important;
}
.hr-double-dashed-border
{
  border-top: 2px dashed #f9f9f9;
}
.statistic .icon-lg {
  width: 70px;
  height: 70px;
  line-height: 70px;
  text-align: center;
  min-width: 70px;
  font-size: 30px;
  max-width: 70px;
  color: #fff;
  border-radius: 50px;
  border: 2px solid #f9f9f9;
  margin-right: 15px;
}
.thin-1px-border
{
  border-bottom: 1px dashed #efefef !important;
}
.rounded-dahsed-layout
{
  border: 2px dashed #f9f9f9;
}
.close-application
{
  position: absolute;
  right: 10px;
  top: 20px;
}
.close-application .btn-dark
{
  width: 40px;
    height: 40px;
    border-radius: 50px;
    padding: 5px;
    background: #fff;
    color: #666;
    border: 2px solid #f9f9f9;
    font-size: 18px;
    /* top: -7px; */
    margin-top: -10px;
}
.h-35
{
  height: 35px;
}
input.h-35
{
  font-size: 12px !important;
}
.gradient-color
{
  background-color: #fff;
  background-image: linear-gradient(90deg,#f9f9f9 24%,#f9f9f9 50%,#fff 0,#fff 0);
}
@include media-breakpoint-down(xs) 
{

  .gradient-color
  {
    background-image: none !important;
    background-color: #f5f5f5 !important;
  }
} 
@include media-breakpoint-down(sm) 
{

  .gradient-color
  {
    background-image: none !important;
    background-color: #f5f5f5 !important;
  }
}  
@media (min-width: 768px) and (max-width: 991.98px) {

  .gradient-color
  {
    background-image: none !important;
    background-color: #f5f5f5 !important;
  }
}  
.font-size-16-p
{
  font-size: 16px;
}
.font-size-18-p
{
  font-size: 18px;
}